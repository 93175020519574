import { Header1, Header5, Text3, Text4 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import JudgeStatisticTexture from 'images/judge/JudgeStatisticTexture.svg'
import testAvatar from 'images/Avatar_test.png'

import { $currentClaim } from 'stores/judgeStore'
import NickTrimmer from 'components/NickTrimmer'
import Statistic from 'components/Judge/ClaimInfo/PlayerStatistic'
import usePlayerStatisticCS from 'hooks/usePlayerStatisticCS'

const TicketStatistic = () => {
  const { t } = useTranslation()

  const claim = useStore($currentClaim)
  const player = claim?.reportedUser

  if (!player?.CSProfileStat) return null

  const {
    KD,
    HS,
    ADR,
    score,
    winratio,
    winCount,
    drawCount,
    loseCount,
    totalMatchCount,
  } = usePlayerStatisticCS(player.CSProfileStat)

  return (
    <>
      <StyledStatistic>
        <StyledPersonInfo>
          <StyledPersonContainer>
            <StyledPersonStatus>
              <SetTicketInfo>
                {t('judge.reportModal.defendant')}
                <MarginTicketInfo>
                  <StyledGrayDescription>
                    {t('judge.reportModal.statistic')}
                  </StyledGrayDescription>
                </MarginTicketInfo>
              </SetTicketInfo>
            </StyledPersonStatus>
            <PlayerInfo>
              <StyledWinrateContainer>
                <StyledPlayerStatistic>
                  {t('judge.reportModal.winrate')}
                </StyledPlayerStatistic>
                <StyledWinrate>{winratio}%</StyledWinrate>
              </StyledWinrateContainer>
              <PlayerName>
                <StyledNickContainer>
                  <NickTrimmer>{player.name}</NickTrimmer>
                </StyledNickContainer>
                <PlayerAvatar src={player.imgUrl || testAvatar} />
              </PlayerName>
            </PlayerInfo>
          </StyledPersonContainer>
          <PlayerStatisticContainer>
            <PlayerStatisticHeader>
              <PlayerInfoContainer>
                <SetPlayerInfo>{KD}</SetPlayerInfo>
                <MarginTicketInfo>
                  <StyledGrayDescription>KD</StyledGrayDescription>
                </MarginTicketInfo>
              </PlayerInfoContainer>
              <PlayerInfoContainer>
                <SetPlayerInfo>{score}</SetPlayerInfo>
                <MarginTicketInfo>
                  <StyledGrayDescription>Score</StyledGrayDescription>
                </MarginTicketInfo>
              </PlayerInfoContainer>
              <PlayerInfoContainer>
                <SetPlayerInfo>{HS}%</SetPlayerInfo>
                <MarginTicketInfo>
                  <StyledGrayDescription>HS</StyledGrayDescription>
                </MarginTicketInfo>
              </PlayerInfoContainer>
              <PlayerInfoContainer>
                <SetPlayerInfo>{ADR}</SetPlayerInfo>
                <MarginTicketInfo>
                  <StyledGrayDescription>ADR</StyledGrayDescription>
                </MarginTicketInfo>
              </PlayerInfoContainer>
            </PlayerStatisticHeader>
            <PlayerMatchesStatistic>
              <PlayerMatches>
                <MatchDescriptionContainer>
                  <MatchDescription>
                    <Circle color="#00D656" />
                    {t('judge.reportModal.win')}
                  </MatchDescription>
                  <MatchCount>{winCount}</MatchCount>
                </MatchDescriptionContainer>
                <MatchDescriptionContainer>
                  <MatchDescription>
                    <Circle color="#F2B927" />
                    {t('judge.reportModal.draw')}
                  </MatchDescription>
                  <MatchCount>{drawCount}</MatchCount>
                </MatchDescriptionContainer>
                <MatchDescriptionContainer>
                  <MatchDescription>
                    <Circle color="#F84848" />
                    {t('judge.reportModal.lose')}
                  </MatchDescription>
                  <MatchCount>{loseCount}</MatchCount>
                </MatchDescriptionContainer>
              </PlayerMatches>
              <PlayerMatchesTotal>
                <PlayerMatchesCount>{totalMatchCount}</PlayerMatchesCount>
                <StyledGrayDescription>
                  {t('judge.reportModal.matchesCount')}
                </StyledGrayDescription>
              </PlayerMatchesTotal>
            </PlayerMatchesStatistic>
          </PlayerStatisticContainer>
        </StyledPersonInfo>
        <StyledProcent>
          <Statistic />
        </StyledProcent>
      </StyledStatistic>
    </>
  )
}
export default TicketStatistic

const StyledStatistic = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 18px;
`
const StyledProcent = styled.div`
  height: 100%;
`

const SetTicketInfo = styled(Text3)`
  &:not(nth-child(2)) {
    line-height: 31px;
    margin: 0px 0px 7px 0px;
  }
`
const MarginTicketInfo = styled(Text4)`
  display: flex;
  height: 20px;
  margin: 0px 29px 0px 0px;
  align-items: center;
`
const StyledGrayDescription = styled.div`
  opacity: 0.3;
`

const StyledPersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StyledPersonStatus = styled.div`
  line-height: 29px;
`
const StyledPersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 978px;
  gap: 45px;
  padding: 22px;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  background-image: url('${JudgeStatisticTexture}');
`
const StyledPlayerStatistic = styled(Text4)`
  line-height: 4px;
  opacity: 0.3;
`
const PlayerInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 92px;
  width: 551px;
  padding: 20px 0px 0px 0px;
  margin-top: -22px;
  margin-right: -22px;
  border-left: 1px solid #ffffff26;
  border-bottom: 1px solid #ffffff26;
  border-radius: 0px 0px 0px 25px;
`
const StyledWinrateContainer = styled.div`
  margin: 0px 0px 0px 24px;
  padding: 11px 25px 11px 25px;
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);

  /* BlurEffect */
  backdrop-filter: blur(7.5px);
`
const StyledWinrate = styled(Text4)`
  margin: 0px 0px 0px 8px;
  color: #2cff80;
`

const StyledNickContainer = styled.div`
  max-width: 250px;
`

const PlayerName = styled(Text3)`
  display: flex;
`
const PlayerAvatar = styled.img`
  margin: 0px 22px 0px 19px;
  border: 1px solid gray;
  width: 42px;
  height: 42px;
  border-radius: 3px;
`
const PlayerStatisticContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const PlayerInfoContainer = styled.div`
  line-height: 31px;
`
const PlayerStatisticHeader = styled.div`
  display: flex;
  gap: 48px;
`
const SetPlayerInfo = styled(Text3)``
const PlayerMatchesStatistic = styled(Text4)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const PlayerMatches = styled.div`
  margin: 0px 51px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const MatchDescriptionContainer = styled.div`
  margin: 0px 0px 8px 0px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`
const MatchDescription = styled(Text4)`
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0px 11px 0px 0px;
`
const MatchCount = styled(Header5)``
const Circle = styled.div`
  margin: 0px 12px 0px 0px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
`
const PlayerMatchesCount = styled(Header1)`
  line-height: 50px;
`
const PlayerMatchesTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
`
