import dayjs from 'dayjs'

export const formatDate = (date: string | Date, template = 'DD.MM.YYYY') =>
  dayjs(date).format(template)

export const getDateParts = (date: string) => {
  const formatted = formatDate(date, 'DD-MM-YY')

  return formatted.split('-')
}

export const getMinutesAndSeconds = (diffInSeconds: number) => {
  const minutes = Math.floor(diffInSeconds / 60)
  const seconds = diffInSeconds - 60 * minutes

  const formattedMinutes = ('0' + minutes).slice(-2)
  const formattedSeconds = ('0' + seconds).slice(-2)

  return [formattedMinutes, formattedSeconds]
}
