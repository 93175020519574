import {
  $isShowExitPopup,
  closeAllModals,
  closeCurrentModal,
  setIsShowExitPopup,
} from 'stores/appStore'
import { $userProfile } from 'stores/userStore'
import { Outlet, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { logout } from 'stores/authStore'
import { useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Bar from 'components/FriendsBar/Bar'
import Chat from 'components/Chat/Chat'
import ConfirmActionPopup from 'components/Modals/ConfirmActionPopup'
import Header from 'components/Header'
import InfoTooltipContainer from 'components/Tooltip/Info/InfoTooltipContainer'
import ModalPages from 'components/ModalPages'
import WindowLine from 'components/WindowLine'
import routes from 'router/routes'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(100% - 31px);
`

const StyledMainPart = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
`

const MainLayout: React.FC = () => {
  const { t } = useTranslation()
  const userProfile = useStore($userProfile)
  const isShowExitPopup = useStore($isShowExitPopup)

  const navigate = useNavigate()

  useEffect(() => {
    if (userProfile && !userProfile?.steamId) {
      navigate(routes.registration())
    }
  }, [userProfile, navigate])

  if (!userProfile) {
    return null
  }

  const onLogoutClick = async () => {
    closeAllModals()
    await logout()
    await setIsShowExitPopup(false)
  }

  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeCurrentModal()
      }
    }

    document.addEventListener('keydown', escFunction, false)
    return () => document.removeEventListener('keydown', escFunction, false)
  }, [])

  return (
    <>
      <WindowLine />
      <InfoTooltipContainer />

      <StyledWrapper id="app">
        <StyledMainPart>
          <Header />
          <ModalPages />
          <Chat />
          <ToastContainer limit={3} />
          <Outlet />
        </StyledMainPart>
        <Bar />

        <ConfirmActionPopup
          isOpen={isShowExitPopup}
          onAccept={onLogoutClick}
          caption={t('modal.logout.caption')}
          declineTitle={t('modal.logout.back')}
          acceptTitle={t('modal.logout.logout')}
          description={t('modal.logout.description')}
          onDecline={() => setIsShowExitPopup(false)}
        />
      </StyledWrapper>
    </>
  )
}

export default MainLayout
