/* eslint-disable @typescript-eslint/no-explicit-any */
import { $party, setParty } from 'stores/partyStore'

const partyListiner = async (data: any) => {
  if (data.entityAction === 'UPDATE') {
    $party.get()?.id === data.data.id && (await setParty(data.data))
  }

  if (data.entityAction === 'DELETE') {
    setParty(null)
    console.log('Пати удалена')
  }

  // if (data.entityAction === 'DI') {
  //   setParty(null)
  //   console.log('Пати удалена')
  // }
}

export default partyListiner
