import { Header2, Header6, Text5 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $currentClaim } from 'stores/judgeStore'
import { EnumJudgeClaimStatus } from 'api/schemas/judgeApi'
import JudgeVoteTexture from 'images/judge/JudgeVoteTexture.svg'
import RemainingTimer from 'components/RemainingTimer/RemainingTimer'
import VoteStatus from 'components/Judge/ClaimInfo/VoteStatus'

interface VoteProcessProps {
  totalMinutesToDecide: number
  remainingMinutesToDecide: number
}

const VoteProcess = ({
  totalMinutesToDecide,
  remainingMinutesToDecide,
}: VoteProcessProps) => {
  const { t } = useTranslation()

  const claim = useStore($currentClaim)

  if (!claim?.myDecision) return null

  const { myDecision, decisionCount = 0, maxDecisionCount = 0 } = claim

  const otherDecisions =
    decisionCount -
    (myDecision.status === EnumJudgeClaimStatus.JudgeClaimStatusFinished
      ? 1
      : 0)

  return (
    <StyledWrapper>
      <StyledResultContainer>
        {remainingMinutesToDecide > 0 && (
          <StyledRemainingTimerWrapper>
            <RemainingTimer
              currentProgress={remainingMinutesToDecide}
              total={totalMinutesToDecide}
              size={103}
            />
          </StyledRemainingTimerWrapper>
        )}
        <StyledStatTitle>{t('judge.reportModal.decision')}</StyledStatTitle>
      </StyledResultContainer>

      {maxDecisionCount > 0 && (
        <JudgeStatus>
          {Array.from({ length: maxDecisionCount }).map((_other, i) => {
            const owner = !!myDecision.status && i === maxDecisionCount - 1
            const status = owner
              ? myDecision.status
              : i < otherDecisions
                ? EnumJudgeClaimStatus.JudgeClaimStatusFinished
                : undefined

            return <VoteStatus key={i} status={status} owner={owner} />
          })}
        </JudgeStatus>
      )}
    </StyledWrapper>
  )
}

const StyledRemainingTimerWrapper = styled.div`
  margin-right: 15px;

  ${Header6} {
    font-size: 26px;
  }

  ${Text5} {
    font-size: 16px;
  }
`

const StyledWrapper = styled.div`
  padding: 34px 113px 34px 34px;
  display: flex;
  width: 100%;
  align-items: center;
  height: 177px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  justify-content: space-between;
  background: url(${JudgeVoteTexture});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0px 0px 24px 0px;

  /* BlurEffect */
  backdrop-filter: blur(7.5px);
`
const StyledResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledStatTitle = styled(Header2)`
  margin-left: 85px;

  text-transform: uppercase;
`
const JudgeStatus = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export default VoteProcess
