import { $modals } from 'stores/appStore'
import { Fragment, ReactNode } from 'react'
import { useStore } from '@nanostores/react'

import { ModalTypes } from 'types/modals'
import MatchResult1x1CS from 'components/MatchResults/MatchResult1x1CS'
import MatchResults from 'components/MatchResults/MatchResults'
import ReportModal from 'components/Judge/Report/ReportModal'
import SettingsPage from 'pages/SettingsPage'
import UserAccount from 'components/PersonalAccount/UserAccount'

const modalsMap: Record<ModalTypes, ReactNode> = {
  report: <ReportModal />,
  personal: <UserAccount />,
  settings: <SettingsPage />,
  matchResults: <MatchResults />,
  matchResults1x1: <MatchResult1x1CS />,
}

const ModalPages = () => {
  const modals = useStore($modals)

  return modals.map((modalType) => (
    <Fragment key={modalType}>{modalsMap[modalType]}</Fragment>
  ))
}

export default ModalPages
