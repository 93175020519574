import { $isOffline, $isSocketOpen } from 'stores/appStore'
import { $lobby, getLobby } from 'stores/lobbyStore'
import { $party, getParty } from 'stores/partyStore'
import { $userProfile, getUserProfile } from 'stores/userStore'
import { Id, toast } from 'react-toastify'
import { getChatsFriends } from 'stores/chatStores/singleChat'
import { getFriends } from 'stores/friendsStore'
import { getGroupsChats } from 'stores/chatStores/groupChats'
import { getNotifications } from 'stores/chatStores/notificationsStore'
import { logError } from 'utils/logger'
import { v4 as uuidv4 } from 'uuid'
import api, { isProd, refreshAccessToken, verifyToken } from 'api/api'
import chatListener from 'sockets/chatListener'
import dayjs from 'dayjs'
import friendListener from 'sockets/friendListener'
import friendsListener from 'sockets/friendsListener'
import gameListiner from 'sockets/gameListiner'
import lobbyListiner from 'sockets/lobbyListiner'
import matchListiner from 'sockets/matchListiner'
import matchmakingListiner from 'sockets/matchmakingListiner'
import notificationListiner from 'sockets/notificationListiner'
import partyListiner from 'sockets/partyListiner'
import showReconnection from 'utils/showReconnection'
import userListiner from 'sockets/userListener'
import walletListiner from 'sockets/walletListiner'

let lastPingDate: Date | null = null
let lastPongDate: Date | null = null
let timer: NodeJS.Timeout | undefined = undefined

const BASE_URL = isProd ? 'wss://f2f.vin' : 'wss://test.f2f.vin'
const BASE_URL_WITH_PORT = `${BASE_URL}:${isProd ? '30' : '31'}`

const initSocket = async (
  connectionPopup?: Id,
  isInit?: boolean,
  retray?: number
) => {
  if (!$userProfile.get() || (retray && retray > 2)) {
    return
  }

  if ($isSocketOpen.get()) {
    return
  }

  try {
    const data = (await api.secret.postSecret()) as { secret: string }
    const secret = data.secret

    const socket = new WebSocket(
      `${BASE_URL_WITH_PORT}011/connection?secret=${secret}&userId=${$userProfile.get()?.id}`
    )

    if (!connectionPopup) {
      const id = uuidv4()

      connectionPopup = showReconnection({ id })
    }

    const createPingTimeout = (socket: WebSocket) => {
      timer = setTimeout(() => {
        if (!$userProfile.get()) {
          socket.close()
          return
        }
        if (
          (lastPingDate &&
            lastPongDate &&
            lastPingDate.getTime() > lastPongDate.getTime()) ||
          (lastPingDate && !lastPongDate)
        ) {
          window.ipcRenderer.invoke('log', {
            level: 'error',
            text: `Сообщение pong не получено за 10 сек.`,
          })
        }

        socket.send('ping')
        window.ipcRenderer.invoke('log', {
          level: 'info',
          text: `Сообщение ping отправлено`,
        })
        createPingTimeout(socket)
        lastPingDate = new Date()
      }, 10000)
    }

    socket.onopen = async function () {
      toast.dismiss(connectionPopup)
      $isSocketOpen.set(true)
      if (!isInit) {
        await getUserProfile()
        await getFriends()

        await getLobby()
        await getParty()

        if ($lobby.get()?.id || $party.get()?.id) {
          await getGroupsChats()
        }

        await getChatsFriends()
        await getNotifications()
      }

      window.ipcRenderer.invoke('log', {
        level: 'info',
        text: 'Соединение установлено',
      })

      console.log('Соединение установлено')

      createPingTimeout(socket)
    }

    socket.onmessage = async (event) => {
      const currentDate = new Date()
      const parsedData = event.data !== 'pong' ? JSON.parse(event.data) : null
      if (event.data === 'pong') {
        window.ipcRenderer.invoke('log', {
          level: 'info',
          text: `Получен pong. Ping отправлен ${dayjs(currentDate).diff(
            lastPingDate,
            'second'
          )} сек. назад.`,
        })

        lastPongDate = currentDate
      }

      if (!parsedData) {
        return
      }

      switch (parsedData.entityName) {
        case 'NOTIFICATION':
          await notificationListiner(parsedData)
          break
        case 'USER':
          await userListiner(parsedData)
          break
        case 'PARTY':
          await partyListiner(parsedData)
          break
        case 'FRIENDS':
          await friendsListener(parsedData)
          break
        case 'FRIEND_STATE':
          await friendListener(parsedData)
          break
        case 'LOBBY':
          await lobbyListiner(parsedData)
          break
        case 'CHAT':
          await chatListener(parsedData)
          break
        case 'GAME':
          await gameListiner(parsedData)
          break
        case 'MATCH':
          await matchListiner(parsedData)
          break
        case 'MATCHMAKING':
          await matchmakingListiner(parsedData)
          break
        case 'WALLET':
          await walletListiner(parsedData)
          break
        default:
          break
      }
    }

    socket.onclose = () => {
      console.log('Соединение закрыто')
      clearTimeout(timer)
      window.ipcRenderer.invoke('log', {
        level: 'info',
        text: 'Соединение закрыто',
      })
      socket.close()
      $isSocketOpen.set(false)
      if ($userProfile.get()) {
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          initSocket(connectionPopup)
        }, 2000)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socket.onerror = async (error: any) => {
      clearTimeout(timer)
      console.log(`Ошибка: ${JSON.parse(error)}`)

      const isValidToken = await verifyToken()
      if (!isValidToken && !$isOffline) {
        await refreshAccessToken()
      } else {
        window.ipcRenderer.invoke('log', {
          level: 'error',
          text: `Ошибка: ${JSON.parse(error)}`,
        })
        console.log(`Ошибка: ${JSON.parse(error)}`)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    setTimeout(() => {
      void initSocket(undefined, false, retray === undefined ? 0 : retray + 1)
    }, 2000)

    logError('Ошибка подключения сокета', e)
  }
}

export default initSocket
