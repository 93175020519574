import { DtoAttachment } from 'api/schemas/judgeApi'

export const downloadFile = (fileURL: string) => {
  if (window.IS_WEB) {
    window.shell.openExternal(fileURL)
  } else {
    window.ipcRenderer.invoke('download', {
      payload: {
        fileURL,
      },
    })
  }
}

export const downloadAttachments = (attachments: DtoAttachment[]) => {
  if (window.IS_WEB) {
    attachments.forEach(({ attachmentUrl }) => downloadFile(attachmentUrl!))
  } else {
    window.ipcRenderer.invoke('save-attachments-zip', attachments)
  }
}
