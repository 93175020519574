import { ReactNode } from 'react'
import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'

import { $match } from 'stores/lobbyStore'
import { DtoMatchParticipant } from 'api/schemas/matchApi'
import { Text4 } from 'styles/typography'
import TeamParticipants from 'components/MatchResults/TeamParticipants'
import WithTooltip from 'components/Tooltip/TableHeader/WithTooltip'

export type PlayerRenderer = (player: DtoMatchParticipant) => ReactNode
export type PlayerExtraCardType = 'mvp' | 'report'

interface ResultsMatchScoreboardProps {
  renderPlayerActions?: PlayerRenderer
  playerExtraCardType: PlayerExtraCardType
  reportedUserId?: string
}

const ResultsMatchScoreboard = ({
  reportedUserId,
  renderPlayerActions,
  playerExtraCardType,
}: ResultsMatchScoreboardProps) => {
  const { t } = useTranslation()
  const match = useStore($match)

  if (!match) return null

  return (
    <StyledRoot>
      <StyledRightPart>
        <StyledTableHead>
          <StyledTableHeadMainPart>
            <div></div>
            <WithTooltip
              title={t('results.tooltips.kill.title')}
              text={t('results.tooltips.kill.description')}
            >
              <StyledTableHeadItem>K</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.death.title')}
              text={t('results.tooltips.death.description')}
            >
              <StyledTableHeadItem>D</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.assist.title')}
              text={t('results.tooltips.assist.description')}
            >
              <StyledTableHeadItem>A</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.diff.title')}
              text={t('results.tooltips.diff.description')}
            >
              <StyledTableHeadItem>+/-</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.kd.title')}
              text={t('results.tooltips.kd.description')}
            >
              <StyledTableHeadItem>K/D</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.adr.title')}
              text={t('results.tooltips.adr.description')}
            >
              <StyledTableHeadItem>ADR</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.hs.title')}
              text={t('results.tooltips.hs.description')}
            >
              <StyledTableHeadItem>HS%</StyledTableHeadItem>
            </WithTooltip>
            <WithTooltip
              title={t('results.tooltips.kast.title')}
              text={t('results.tooltips.kast.description')}
            >
              <StyledTableHeadItem>KAST</StyledTableHeadItem>
            </WithTooltip>
          </StyledTableHeadMainPart>
          <WithTooltip
            title={t('results.tooltips.ef.title')}
            text={t('results.tooltips.ef.description')}
          >
            <StyledTableHeadItem>EF</StyledTableHeadItem>
          </WithTooltip>
          <StyledTableHeadItem>SCORE</StyledTableHeadItem>
          <StyledTableHeadItem>RATING</StyledTableHeadItem>
          <StyledTableHeadItem>REWARD</StyledTableHeadItem>
        </StyledTableHead>
        <StyledTableParts>
          <StyledTablePart>
            <StyledTableBody>
              <TeamParticipants
                match={match}
                teamColor="RED"
                renderPlayerActions={renderPlayerActions}
                playerExtraCardType={playerExtraCardType}
                reportedUserId={reportedUserId}
              />
            </StyledTableBody>
          </StyledTablePart>
          <StyledTablePart>
            <StyledTableBody>
              <TeamParticipants
                match={match}
                teamColor="BLUE"
                renderPlayerActions={renderPlayerActions}
                playerExtraCardType={playerExtraCardType}
                reportedUserId={reportedUserId}
              />
            </StyledTableBody>
          </StyledTablePart>
        </StyledTableParts>
      </StyledRightPart>
    </StyledRoot>
  )
}

const StyledRoot = styled.div`
  height: 100%;
  display: flex;
  height: 100%;
  gap: 40px;
`

const StyledRightPart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 21px;
`

const StyledTableParts = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`

const StyledTableHead = styled.div`
  display: grid;
  grid-template-columns: 940px 77px repeat(3, 120px);
  align-items: center;
  gap: 8px;
`

const StyledTableHeadMainPart = styled.div`
  display: grid;
  grid-template-columns: 270px repeat(8, 70px);
  padding: 0 16px;
  gap: 11px;
`

const StyledTablePart = styled.div`
  display: flex;
  height: 326px;
`

const StyledTableHeadItem = styled(Text4)`
  color: rgba(255, 255, 255, 0.5);
  padding: 16px 0;
  text-align: center;
`

const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export default ResultsMatchScoreboard
