import { ChangeEvent } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  $currentJudgeClaim,
  addFileToJudgeClaim,
  removeFileFromJudgeClaim,
} from 'stores/judgeStore'
import { Text5, Text6 } from 'styles/typography'
import AddFile from 'images/judge/AddFile'
import FileTextImage from 'images/judge/FileTextImage.svg'
import RemoveAttachmentIcon from 'images/RemoveAttachmentIcon.svg'

const JudgeExperienceAttachmentsInput = () => {
  const { t } = useTranslation()
  const claim = useStore($currentJudgeClaim)

  if (!claim) return null

  const { attachments = [], id: claimId = '' } = claim

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      await addFileToJudgeClaim(claimId, file)
      e.target.value = ''
    }
  }

  return (
    <StyledFileWrapper>
      <StyledFileText>{t('judge.welcomePage.file')}</StyledFileText>
      <StyledFileLabel>
        {attachments.length === 3 ? (
          <Text5>{t('judge.welcomePage.maxUploadFiles')}</Text5>
        ) : (
          <>
            <StyledPlusButton />
            <AcceptedFilesHint>JPG,PNG,PDF</AcceptedFilesHint>
            <StyledFileInput
              disabled={attachments.length === 3}
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={handleFileChange}
            />
          </>
        )}
      </StyledFileLabel>
      <StyledAttachmentsWrapper>
        {attachments.map((a) => (
          <StyledAttachmentContainer key={a.attachmentId}>
            <StyledAttachmentIcon />
            <StyledAttachmentName>{a.attachmentName}</StyledAttachmentName>
            <StyledRemoveAttachmentIcon
              onClick={() => removeFileFromJudgeClaim(claimId, a)}
            />
          </StyledAttachmentContainer>
        ))}
      </StyledAttachmentsWrapper>
    </StyledFileWrapper>
  )
}

const StyledFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
const StyledFileText = styled(Text6)`
  color: white;
  opacity: 0.3;
`
const StyledFileInput = styled.input`
  display: none;
`
const StyledFileLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  height: 65px;
  border-radius: 3px;
  border: 1px dashed rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  cursor: pointer;
  transition: 0.2s;

  & svg {
    fill: white;
  }

  &:hover {
    border-color: #ffcd29;
    background: rgba(238, 157, 62, 0.15);

    & svg {
      fill: #ffcd29;
    }
  }
`
const StyledPlusButton = styled(AddFile)`
  width: 24px;
  height: 24px;
`

const StyledAttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StyledAttachmentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

const StyledAttachmentIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${FileTextImage});
  background-repeat: no-repeat;
  background-position: center center;
`

const StyledRemoveAttachmentIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${RemoveAttachmentIcon});
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`

const StyledAttachmentName = styled(Text6)`
  flex: 1;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  opacity: 0.3;
`

const AcceptedFilesHint = styled(Text6)`
  color: #fff;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  opacity: 0.3;
`

export default JudgeExperienceAttachmentsInput
