import { useMemo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { DtoClaimPreview } from 'api/schemas/judgeApi'
import { Header5, Header6, Text5 } from 'styles/typography'
import {
  defaultTableGridStyles,
  tableGridStylesWithTimer,
} from 'components/ClaimsTable/styles'
import { formatDate } from 'utils/date'
import {
  useClaimReasonTranslations,
  useClaimStatusTranslations,
  useGuiltyTranslations,
} from 'components/ClaimsTable/translations'
import CSGame from 'images/LogoCS.svg'
import CSGameBlack from 'images/RankGameCSBlack.svg'
import RemainingTimer from 'components/RemainingTimer/RemainingTimer'
import WithTooltip from 'components/Tooltip/TableHeader/WithTooltip'
import testAvatar from 'images/Avatar_test.png'

interface ClaimsTableRowProps {
  claim: DtoClaimPreview
  withTimer?: boolean
  onClick?: () => void
}

const ClaimsTableRow = ({
  claim,
  onClick,
  withTimer = false,
}: ClaimsTableRowProps) => {
  const claimReasonLabels = useClaimReasonTranslations()
  const { statusLabels } = useClaimStatusTranslations()
  const { toGuiltyLabel } = useGuiltyTranslations()

  const { total, remaining } = useMemo(() => {
    const result = { total: 0, remaining: 0 }

    if (!claim.myDecision?.expiredAt || !claim.myDecision?.takeToWork)
      return result

    const expiredAt = dayjs(claim.myDecision.expiredAt)

    if (expiredAt.isBefore(new Date())) return result

    result.total = expiredAt.diff(claim.myDecision.takeToWork, 'minutes')
    result.remaining = expiredAt.diff(new Date(), 'minutes')

    return result
  }, [claim.myDecision])

  return (
    <StyledWrapper $withTimer={withTimer} onClick={onClick}>
      {withTimer && (
        <RemainingTimer total={total} currentProgress={remaining} />
      )}
      <StyledId>{claim.externalId}</StyledId>
      <StyledLogo />
      <StyledPlayer>
        <StyledAvatar $avatarUrl={claim.reportedUser?.imgUrl || testAvatar} />
        <StyledUserName>{claim.reportedUser?.name}</StyledUserName>
      </StyledPlayer>
      <WithTooltip
        text={claim.reasons!.map((r) => claimReasonLabels[r]).join(', ')}
      >
        <StyledReasons>
          {claim.reasons!.map((r) => (
            <StyledReason key={r}>{claimReasonLabels[r]}</StyledReason>
          ))}
        </StyledReasons>
      </WithTooltip>
      <StyledStatus>{statusLabels[claim.status!]}</StyledStatus>
      <StyledIsGuilty $isGuilty={claim.isGuiltyVerdict!}>
        {toGuiltyLabel(claim.isGuiltyVerdict!)}
      </StyledIsGuilty>
      <StyledJudjeDecisionsWrapper>
        <StyledJudjeDecisionsNumber>
          {claim.decisionCount}
          <StyledShadowItem>/{claim.maxDecisionCount}</StyledShadowItem>
        </StyledJudjeDecisionsNumber>
        <StyledJudjeDecisionsItems>
          {Array.from({ length: claim.maxDecisionCount! }).map((_, index) => (
            <StyledJudjeDecisionsItem
              key={index}
              $filled={claim.decisionCount! > index}
            />
          ))}
        </StyledJudjeDecisionsItems>
      </StyledJudjeDecisionsWrapper>
      <StyledDate>{formatDate(claim.createdAt!)}</StyledDate>
    </StyledWrapper>
  )
}

export default ClaimsTableRow

interface StyledJudjeDecisionsItemProps {
  $filled?: boolean
}

const StyledJudjeDecisionsItem = styled.div<StyledJudjeDecisionsItemProps>`
  width: 9px;
  height: 9px;
  border-radius: 50%;

  border: 1px solid
    ${({ $filled }) => ($filled ? '#ffcd29' : 'rgba(255, 255, 255, 0.15)')};
  background: ${({ $filled }) =>
    $filled ? '#ffcd29' : 'rgba(255, 255, 255, 0.03))'};
`

const StyledJudjeDecisionsItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 48px;
  column-gap: 4px;
  row-gap: 6px;
  align-items: center;
  justify-content: center;
`

const StyledJudjeDecisionsNumber = styled(Header6)`
  font-weight: 400;
`
const StyledShadowItem = styled.span`
  opacity: 0.3;
`

const StyledJudjeDecisionsWrapper = styled.div`
  display: flex;
  gap: 16px;

  align-items: center;
  justify-content: center;
`

interface StyledWrapperProps {
  $withTimer: boolean
}

const StyledWrapper = styled.div<StyledWrapperProps>`
  ${({ $withTimer }) =>
    $withTimer ? tableGridStylesWithTimer : defaultTableGridStyles};

  align-items: center;
  min-height: 80px;
  padding: 16px 32px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(7.5px);
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: rgba(238, 157, 62, 0.15);
  }

  &:active {
    background: #ffcd29;
    color: black;
  }
`
const StyledId = styled(Header5)`
  text-align: center;
  font-weight: 600;
  opacity: 0.3;

  ${StyledWrapper}:active & {
    opacity: 1;
    color: black;
  }
`
const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  background-image: url('${CSGame}');
  background-repeat: no-repeat;
  background-position: center;

  ${StyledWrapper}:active & {
    background-image: url('${CSGameBlack}');
  }
`
const StyledPlayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

interface StyledAvatarProps {
  $avatarUrl: string
}

const StyledAvatar = styled.div<StyledAvatarProps>`
  width: 24px;
  height: 24px;
  background-image: url(${({ $avatarUrl }) => $avatarUrl});
  background-size: contain;
`

const StyledUserName = styled(Header5)`
  max-width: 240px;
  font-weight: 600;
  opacity: 0.3;
  text-transform: uppercase;
  text-align: center;

  ${StyledWrapper}:active & {
    opacity: 1;
    color: black;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledReason = styled(Header5)`
  font-weight: 600;
  opacity: 0.3;
  text-transform: uppercase;
  text-align: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${StyledWrapper}:active & {
    opacity: 1;
    color: black;
  }
`

const StyledStatus = styled(Text5)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
`
interface StyledIsGuiltyProps {
  $isGuilty: boolean | null
}
const StyledIsGuilty = styled(StyledStatus)<StyledIsGuiltyProps>`
  background: ${({ $isGuilty }) => {
    switch ($isGuilty) {
      case true:
        return '#69F425'
      case false:
        return '#F44'
      default:
        return 'rgba(255, 255, 255, 0.03)'
    }
  }};
  color: ${({ $isGuilty }) => `${$isGuilty ? 'black' : 'white'}`};
`
const StyledDate = styled(Header6)`
  opacity: 0.3;
`

const StyledReasons = styled.div``
