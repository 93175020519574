import { useStore } from '@nanostores/react'
import styled from 'styled-components'

import { $currentClaim } from 'stores/judgeStore'
import LongVoterCard from 'components/Judge/TicketResult/LongVoterCard'
import ShortVoterCard from 'components/Judge/TicketResult/ShortVoterCard'
import TicketDate from 'components/Judge/ClaimInfo/TicketDate'

const JudgeClaimResults = () => {
  const claim = useStore($currentClaim)

  if (!claim?.decisions) return null

  return (
    <StyledWrapper>
      <TicketDate />

      <StyledVoteCards>
        {claim.decisions.map((decision, index) => {
          if (!decision) return null

          const VoterCard =
            claim.decisionCount === 3 ? ShortVoterCard : LongVoterCard

          return (
            <VoterCard
              key={decision.id}
              decision={decision}
              position={index + 1}
            />
          )
        })}
      </StyledVoteCards>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  height: 545px;
  display: flex;
  flex: 1;
  gap: 16px;
`

const StyledVoteCards = styled.div`
  max-width: 1492px;
  display: flex;
  gap: 16px;
  flex: 1;
`

export default JudgeClaimResults
