import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header6 } from 'styles/typography'
import { ModalTypes } from 'types/modals'
import { closeModal } from 'stores/appStore'
import Close from 'images/Close'

interface ModalHeaderProps {
  modalType: ModalTypes
}

const ModalHeader = ({ modalType }: ModalHeaderProps) => {
  const { t } = useTranslation()

  return (
    <StyledHeader>
      <StyledTitle>{t(`modal.titles.${modalType}`)}</StyledTitle>
      <StyledClose onClick={() => closeModal(modalType)}>
        <Close />
      </StyledClose>
    </StyledHeader>
  )
}

const StyledClose = styled.div`
  cursor: pointer;
`

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledTitle = styled(Header6)`
  opacity: 0.2;
  text-transform: uppercase;
`

export default ModalHeader
