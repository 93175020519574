import { useMemo } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $match } from 'stores/lobbyStore'
import {
  AppealStageTypes,
  handleCreateNewAppeal,
} from 'stores/chatStores/appealStore'
import { DtoMatchParticipant } from 'api/schemas/matchApi'
import { EnumClaimReportCategory } from 'api/schemas/judgeApi'
import { Text5 } from 'styles/typography'
import { setTabActive } from 'stores/chatStores/chatStore'
import TeamIcons from 'images/userCard/TeamIcons'

interface ScoreboardPlayerActionsProps {
  player: DtoMatchParticipant
}

const ScoreboardPlayerActions = ({ player }: ScoreboardPlayerActionsProps) => {
  const { t } = useTranslation()
  const match = useStore($match)

  const teams = useMemo(() => {
    const tempTeams: string[] = []

    return match?.participants?.reduce((acc, user) => {
      if (user.originLobbyId && !tempTeams.includes(user.originLobbyId)) {
        tempTeams.push(user.originLobbyId)
      }
      if (
        user.originLobbyId &&
        !acc.includes(user.originLobbyId) &&
        tempTeams.includes(user.originLobbyId)
      ) {
        return [...acc, user.originLobbyId]
      }
      return acc
    }, [] as string[])
  }, [match?.participants])

  if (!match) return null

  const handlePlayerReport = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setTabActive('support', true)
    await handleCreateNewAppeal({
      game: 'CS 2',
      reportedMatchId: match.id,
      reportedUserId: player.user?.id,
      nextStep: AppealStageTypes.ADD_FILES,
      prevStep: AppealStageTypes.CHOOSE_PLAYER,
      currentStep: AppealStageTypes.CHOOSE_PLAYER_REASON,
      category: EnumClaimReportCategory.ClaimReportCategoryPlayerReport,
    })
  }

  return (
    <StyledPlayerStat>
      <TeamIcons group={teams?.indexOf(player.originLobbyId || '') || null} />
      <StyledLike>{t('results.like')}</StyledLike>
      <StyledReport onClick={handlePlayerReport}>
        {t('results.report')}
      </StyledReport>
    </StyledPlayerStat>
  )
}

const StyledPlayerStat = styled.div`
  display: flex;
  gap: 16px;
`

const StyledLike = styled(Text5)`
  color: #2cff80;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: #2cff80;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  transition: 0.2s;
  &:hover {
    color: white;
    &:after {
      background-color: white;
    }
  }
`

const StyledReport = styled(Text5)`
  color: #f44;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: #f44;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  transition: 0.2s;
  &:hover {
    color: white;
    &:after {
      background-color: white;
    }
  }
`

export default ScoreboardPlayerActions
