import { Outlet } from 'react-router-dom'
import WindowLine from 'components/WindowLine'
import authBG from 'images/authBG.webp'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-image: url('${authBG}');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
`

const AuthLayout: React.FC = () => {
  return (
    <>
      <WindowLine />
      <StyledWrapper>
        <Outlet />
      </StyledWrapper>
    </>
  )
}

export default AuthLayout
