import { $appealMatchesHistory, getAppealMatches } from 'stores/statisticStore'
import { $userProfile } from 'stores/userStore'
import { DtoChat, DtoMessage } from 'api/schemas/chatApi'
import {
  DtoClaim,
  EnumClaimReason,
  EnumClaimReportCategory,
  EnumCreateClaimStatus,
} from 'api/schemas/supportApi'
import { atom, computed } from 'nanostores'
import { logger } from '@nanostores/logger'
import api from 'api/api'
import scrollChatToBottom from 'utils/scrollChatToBottom'

export enum AppealStageTypes {
  ADD_FILES = 'ADD_FILES',
  CHOOSE_GAME = 'CHOOSE_GAME',
  CHOOSE_MATCH = 'CHOOSE_MATCH',
  CHOOSE_PLAYER = 'CHOOSE_PLAYER',
  CHOOSE_CATEGORY = 'CHOOSE_CATEGORY',
  APPEAL_GENERATED = 'APPEAL_GENERATED',
  CHOOSE_MATCH_REASON = 'CHOOSE_MATCH_REASON',
  CHOOSE_PLAYER_REASON = 'CHOOSE_PLAYER_REASON',
  CHOOSE_SERVICE_REASON = 'CHOOSE_SERVICE_REASON',
}

export type AppealStage = keyof typeof AppealStageTypes | null

export type CategoryType = keyof typeof EnumClaimReportCategory | null
export interface IReasonCheckbox {
  name: string
  value: EnumClaimReason
  isActive: boolean
}
export interface IDownloadFile {
  id: string
  url: string
  name: string
}
export interface CreatedAppeal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files?: any | null
  game?: string | null
  timeCodes?: string[][]
  reportedUserId?: string
  reportedMatchId?: string
  filesName?: string | null
  description?: string | null
  nextStep?: AppealStage | null
  prevStep?: AppealStage | null
  currentStep?: AppealStage | null
  reason?: IReasonCheckbox[] | null
  category?: EnumClaimReportCategory
  downloadFiles?: IDownloadFile[] | null
}

// STORE
export const $appeals = atom<DtoClaim[] | null>(null)
export const $currentAppealID = atom<string | null>(null)
export const $currentAppeal = atom<DtoClaim | null>(null)
export const $createdAppeal = atom<CreatedAppeal>({})
export const $createdAppealDraft = atom<DtoClaim | null>(null)
export const $isCreatingAppeal = atom<boolean>(false)
export const $appealMessages = atom<DtoChat | null>(null)

export const $isCheatAppeal = computed([$currentAppeal], (createdAppeal) => {
  return createdAppeal?.reasons?.includes(EnumClaimReason.ClaimReasonCheating)
})

export const $getMatchPlayers = computed(
  [$appealMatchesHistory, $createdAppeal, $userProfile],
  (appealMatchesHistory, createdAppeal, userProfile) => {
    const userID = userProfile?.id
    return appealMatchesHistory
      ?.find((match) => match.id === createdAppeal?.reportedMatchId)
      ?.participants?.filter((participant) => participant.user?.id !== userID)
  }
)

export const $getReportedPlayers = computed(
  [$createdAppeal, $getMatchPlayers],
  (createdAppeal, getMatchPlayers) => {
    const playersID = createdAppeal.reportedUserId || ''
    const filteredPlayers = getMatchPlayers
      ?.filter((player) => playersID.includes(player.user?.id || ''))
      ?.map((player) => player.user?.name)

    if (!filteredPlayers) return null
    return {
      entirely: filteredPlayers?.join(', '),
      shortly:
        filteredPlayers?.join(', ').length > 9
          ? `${filteredPlayers.join(', ')?.slice(0, 9)}...`
          : filteredPlayers?.join(', '),
    }
  }
)

export const $isAppealEstimated = computed(
  [$currentAppeal],
  (currentAppeal) => {
    return !!currentAppeal?.grade
  }
)

export const $showMessageButtons = computed(
  [$currentAppeal, $isAppealEstimated],
  (currentAppeal, isAppealEstimated) => {
    return currentAppeal?.status === 'FINISH' && !isAppealEstimated
  }
)

export const handleCreateNewAppeal = async (params?: CreatedAppeal) => {
  $isCreatingAppeal.set(true)
  $currentAppealID.set(null)
  if ($createdAppealDraft.get() === null) {
    await api.claim.postClaim()
    const { data } = await api.claim.getDraft()
    $createdAppealDraft.set(data)
  }
  $createdAppeal.set(params ? params : {})
  !params &&
    $createdAppeal.set({ currentStep: AppealStageTypes.CHOOSE_CATEGORY })
}

export const getAppeals = async (executorId?: string, status?: string) => {
  const { data } = await api.claim.getMine({ status }) // список заявок
  if (data) {
    data.forEach(
      (item: DtoClaim) =>
        item.createStatus !== 'COMPLETED' && $createdAppealDraft.set(item)
    )
    return $appeals.set(
      data
        .filter((item: DtoClaim) => item.createStatus === 'COMPLETED')
        .reverse() || null
    )
  }
}

export const getCurrentAppeal = async (id: string) => {
  const { data } = await api.claim.getClaim2(id) // конкретная заявка // список заявок

  return $currentAppeal.set(data || null)
}

export const getAppealMessages = async (id: string) => {
  const { data } = await api.chats.getClaim(id)
  return $appealMessages.set(data || null)
}

export const getMoreMessages = async (fromCreatedAt?: string) => {
  const currentChat = $appealMessages.get()
  const isUnread = !!currentChat?.haveUnreadMessage
  try {
    if (isUnread || fromCreatedAt) {
      const { data } = (await api.chats.getChats(currentChat?.id || '', {
        fromCreatedAt,
      })) as { data: DtoChat }

      if (!currentChat) {
        return
      }

      const messages: DtoMessage[] | undefined = fromCreatedAt
        ? currentChat.messages?.length
          ? [
              ...currentChat.messages.reverse(),
              ...(data.messages as DtoMessage[]),
            ]
          : data.messages
        : data.messages
      const result = { ...currentChat, messages: messages || [] }

      $appealMessages.set(result)
      return Boolean(data.messages?.length)
    } else {
      return $appealMessages
    }
  } catch (e) {
    return
  }
}

export const sendAppeal = async () => {
  try {
    const createdAppeal = $createdAppeal.get()

    const reasons = createdAppeal.reason?.map(({ value }) => value) ?? []

    await api.claim.putClaim({
      claimId: $createdAppealDraft.get()?.id || '',
      status: EnumCreateClaimStatus.CreateClaimStatusCompleted,
      payload: {
        reasons,
        timeCodes: createdAppeal.timeCodes,
        description: createdAppeal.description ?? '',
        reportedUserId: createdAppeal.reportedUserId,
        reportedMatchId: createdAppeal.reportedMatchId,
        category:
          createdAppeal.category ||
          EnumClaimReportCategory.ClaimReportCategoryServiceReport,
      },
    })
    $createdAppealDraft.set(null)
    await getAppeals()
    const appealID = $appeals.get()?.toReversed()?.[0].id
    await chooseAppeal(appealID)

    scrollChatToBottom('appealsScroll')
  } catch (e) {
    console.log(e)
  }
}

export const chooseAppeal = async (id?: string) => {
  if (!id) return
  $isCreatingAppeal.set(false)
  $createdAppeal.set({})
  await getCurrentAppeal(id)
  await getAppealMessages(id)
  $currentAppealID.set(id)
}

export const sendFile = async (file: File) => {
  const { data } = await api.claim.postAttachment(
    $createdAppealDraft.get()?.id || '',
    { file }
  )
  $createdAppealDraft.set({
    ...$createdAppealDraft.get(),
    createStatus: EnumCreateClaimStatus.CreateClaimStatusFileUploading,
  })
  $createdAppeal.set({
    ...$createdAppeal.get(),
    downloadFiles: [
      ...($createdAppeal.get().downloadFiles || []),
      {
        id: data.attachmentId,
        url: data.attachmentUrl,
        name: data.attachmentName,
      },
    ],
  })
}

export const setApproveAppeal = async (
  isApproved: boolean,
  messageId: string
) => {
  const id = $currentAppeal.get()?.id
  if (id) await api.claim.putApprove(id, { messageId, isApproved })
}

export const setEstimateAppeal = async (grade: number) => {
  const id = $currentAppeal.get()?.id
  if (!id) return
  await api.claim.putEstimate(id, { grade })
  $currentAppeal.set({
    ...$currentAppeal.get(),
    grade,
  })
}

export const backToStep = (step: AppealStage) => {
  if (step === AppealStageTypes.CHOOSE_CATEGORY) {
    let nextStep: AppealStage = null
    if (
      $createdAppeal.get().category ===
      EnumClaimReportCategory.ClaimReportCategoryServiceReport
    )
      nextStep = AppealStageTypes.CHOOSE_SERVICE_REASON
    if (
      $createdAppeal.get().category ===
      EnumClaimReportCategory.ClaimReportCategoryPlayerReport
    )
      nextStep = AppealStageTypes.CHOOSE_GAME
    if (
      $createdAppeal.get().category ===
      EnumClaimReportCategory.ClaimReportCategoryMatchReport
    )
      nextStep = AppealStageTypes.CHOOSE_GAME
    $createdAppeal.set({
      nextStep,
      category: $createdAppeal.get().category,
      currentStep: AppealStageTypes.CHOOSE_CATEGORY,
    })
  }
  if (step === AppealStageTypes.CHOOSE_SERVICE_REASON) {
    $createdAppeal.set({
      reason: $createdAppeal.get().reason,
      nextStep: AppealStageTypes.ADD_FILES,
      prevStep: AppealStageTypes.CHOOSE_CATEGORY,
      currentStep: AppealStageTypes.CHOOSE_SERVICE_REASON,
      category: EnumClaimReportCategory.ClaimReportCategoryServiceReport,
    })
  }
  if (step === AppealStageTypes.CHOOSE_GAME) {
    $createdAppeal.set({
      game: $createdAppeal.get().game,
      category: $createdAppeal.get().category,
      nextStep: AppealStageTypes.CHOOSE_MATCH,
      currentStep: AppealStageTypes.CHOOSE_GAME,
      prevStep: AppealStageTypes.CHOOSE_CATEGORY,
    })
  }
  if (step === AppealStageTypes.CHOOSE_MATCH) {
    $createdAppeal.set({
      game: $createdAppeal.get().game,
      prevStep: AppealStageTypes.CHOOSE_GAME,
      category: $createdAppeal.get().category,
      currentStep: AppealStageTypes.CHOOSE_MATCH,
      reportedMatchId: $createdAppeal.get().reportedMatchId,
      nextStep:
        $createdAppeal.get().category ===
        EnumClaimReportCategory.ClaimReportCategoryPlayerReport
          ? AppealStageTypes.CHOOSE_PLAYER
          : AppealStageTypes.CHOOSE_MATCH_REASON,
    })
  }
  if (step === AppealStageTypes.CHOOSE_PLAYER) {
    $createdAppeal.set({
      game: $createdAppeal.get().game,
      category: $createdAppeal.get().category,
      prevStep: AppealStageTypes.CHOOSE_MATCH,
      currentStep: AppealStageTypes.CHOOSE_PLAYER,
      nextStep: AppealStageTypes.CHOOSE_PLAYER_REASON,
      reportedUserId: $createdAppeal.get().reportedUserId,
      reportedMatchId: $createdAppeal.get().reportedMatchId,
    })
  }
  if (step === AppealStageTypes.CHOOSE_PLAYER_REASON) {
    $createdAppeal.set({
      game: $createdAppeal.get().game,
      reason: $createdAppeal.get().reason,
      nextStep: AppealStageTypes.ADD_FILES,
      category: $createdAppeal.get().category,
      prevStep: AppealStageTypes.CHOOSE_PLAYER,
      currentStep: AppealStageTypes.CHOOSE_PLAYER_REASON,
      reportedUserId: $createdAppeal.get().reportedUserId,
      reportedMatchId: $createdAppeal.get().reportedMatchId,
    })
  }
  if (step === AppealStageTypes.CHOOSE_MATCH_REASON) {
    $createdAppeal.set({
      game: $createdAppeal.get().game,
      reason: $createdAppeal.get().reason,
      nextStep: AppealStageTypes.ADD_FILES,
      category: $createdAppeal.get().category,
      prevStep: AppealStageTypes.CHOOSE_MATCH,
      currentStep: AppealStageTypes.CHOOSE_MATCH_REASON,
      reportedMatchId: $createdAppeal.get().reportedMatchId,
    })
  }
  if (step === AppealStageTypes.ADD_FILES) {
    let prevStep = null as AppealStage
    if (
      $createdAppeal.get().category ===
      EnumClaimReportCategory.ClaimReportCategoryServiceReport
    ) {
      prevStep = AppealStageTypes.CHOOSE_SERVICE_REASON
    } else {
      prevStep =
        $createdAppeal.get().category ===
        EnumClaimReportCategory.ClaimReportCategoryPlayerReport
          ? AppealStageTypes.CHOOSE_PLAYER_REASON
          : AppealStageTypes.CHOOSE_MATCH_REASON
    }
    $createdAppeal.set({
      prevStep,
      game: $createdAppeal.get().game,
      files: $createdAppeal.get().files,
      reason: $createdAppeal.get().reason,
      currentStep: AppealStageTypes.ADD_FILES,
      category: $createdAppeal.get().category,
      filesName: $createdAppeal.get().filesName,
      nextStep: AppealStageTypes.APPEAL_GENERATED,
      downloadFiles: $createdAppeal.get().downloadFiles,
      reportedUserId: $createdAppeal.get().reportedUserId,
      reportedMatchId: $createdAppeal.get().reportedMatchId,
    })
  }
}

$createdAppeal.listen(async (createdAppeal) => {
  if (createdAppeal.currentStep === AppealStageTypes.CHOOSE_MATCH) {
    await getAppealMatches()
  }
})

export const deleteFiles = async (id?: string) => {
  const claimId = $createdAppealDraft.get()?.id || ''

  if (!id) {
    // при переводе в draft все файлы удаляются. Если не передали id считаем, что удаляем все
    await api.claim.putClaim({
      claimId,
      status: EnumCreateClaimStatus.CreateClaimStatusDraw,
    })

    $createdAppealDraft.set({
      ...$createdAppealDraft.get(),
      createStatus: EnumCreateClaimStatus.CreateClaimStatusDraw,
    })

    return $createdAppeal.set({
      ...$createdAppeal.get(),
      files: null,
      filesName: null,
      downloadFiles: null,
    })
  }

  await api.claim.deleteAttachment(claimId, id)

  return $createdAppeal.set({
    ...$createdAppeal.get(),
    downloadFiles: $createdAppeal
      .get()
      .downloadFiles?.filter((file) => file.id !== id),
  })
}

export const updateAppealMessagesFromSocket = (data: DtoChat) => {
  const appealMessages = $appealMessages.get()
  if (
    appealMessages?.claimId !== data.claimId ||
    !data.messages?.[0] ||
    !data.messages?.[0].id
  )
    return
  const status = data.messages[0].status
  const messageId = data.messages[0].id
  const oldMessages = appealMessages?.messages

  // const lastMessage = data?.messages?.[data?.messages?.length - 1]

  // if (lastMessage) {
  //   $lastMessageForFastShow.set({
  //     ...lastMessage,
  //     tab: 'support',
  //   })
  // }

  // $currentAppeal.set({
  //   ...$currentAppeal.get(),
  //   status: status || $currentAppeal.get()?.status,
  // })
  // Если нажал на "помогло"\"не помогло", обновляем только статус заявки выше и всех сообщений с закрытием заявки, чтобы кнопки для закрытия\оценки не дублировались
  if (status === 'APPROVE_FAIL' || status === 'APPROVE_SUCCESS') {
    $appealMessages.set({
      ...appealMessages,
      messages: (oldMessages || data.messages)?.map((item: DtoMessage) => {
        const isCurrentMessage = item.id === messageId
        const newStatus = isCurrentMessage
          ? status
          : item.status === 'FINISH'
            ? 'APPROVE_FAIL'
            : item.status
        return {
          ...item,
          status: newStatus,
        }
      }),
    })
    return
  }

  $appealMessages.set({
    ...appealMessages,
    messages: oldMessages
      ? [...data.messages, ...oldMessages]
      : [...data.messages],
  })
}

logger(
  {
    currentAppeal: $currentAppeal,
    createdAppeal: $createdAppeal,
    getMatchPlayers: $getMatchPlayers,
    getReportedPlayers: $getReportedPlayers,
  },
  {
    messages: {
      mount: false,
      unmount: false,
    },
  }
)
