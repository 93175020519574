import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { $match } from 'stores/lobbyStore'
import { $userProfile } from 'stores/userStore'
import {
  AppealStageTypes,
  handleCreateNewAppeal,
} from 'stores/chatStores/appealStore'
import { EnumClaimReportCategory } from 'api/schemas/supportApi'
import {
  Header1,
  Header2,
  Header3,
  SideHeader2,
  SideHeader3,
  Text4,
  Text5,
} from 'styles/typography'
import { setTabActive } from 'stores/chatStores/chatStore'
import MatchItem from 'components/PlayerCardResultCS1x1/MatchItem'
import PlayerCardResultCS1x1 from 'components/PlayerCardResultCS1x1/PlayerCardResultCS1x1'
import SwitchIcon from 'images/SwitchIcon'
import cardBG from 'images/1x1CSResultsStatsBG.png'

interface MatchItemRound {
  result: 'lose' | 'win' | 'drow'
  typeOfWin:
    | 'Elimination'
    | 'HostagesRescued'
    | 'HostagesNotRescued'
    | 'BombDefused'
    | 'BombExploded'
    | null
}

export type Round = Record<string, MatchItemRound>

interface MatchResult1x1CSContentProps {
  useRelativeTeamLabels?: boolean
  reportedUserId?: string
  reportable?: boolean
}

const MatchResult1x1CSContent = ({
  reportedUserId,
  reportable = true,
  useRelativeTeamLabels = true,
}: MatchResult1x1CSContentProps) => {
  const { t } = useTranslation()

  const match = useStore($match)
  const currentUser = useStore($userProfile)

  if (!match || !currentUser) return null

  const teamRed = match.participants?.find(
    ({ teamColor }) => teamColor === 'RED'
  )

  const teamBlue = match.participants?.find(
    ({ teamColor }) => teamColor === 'BLUE'
  )

  if (!teamRed || !teamBlue) {
    return null
  }

  const isRedWinner = match.CSMatchResult?.winTeamColor === teamRed.teamColor
  const isBlueWinner = match.CSMatchResult?.winTeamColor === teamBlue.teamColor

  const redScore = isRedWinner ? match.winTeamScore : match.loseTeamScore

  const blueScore = isBlueWinner ? match.winTeamScore : match.loseTeamScore

  const rounds =
    match.CSMatchResult?.CSMatchRoundResults?.reduce((acc, round) => {
      const yourRound = {
        result:
          round?.winTeamColor?.toUpperCase() === teamRed.teamColor
            ? 'win'
            : 'lose',
        typeOfWin: round.winCondition as
          | 'Elimination'
          | 'HostagesRescued'
          | 'HostagesNotRescued'
          | 'BombDefused'
          | 'BombExploded'
          | null,
      } as MatchItemRound

      const opponentsRound = {
        result:
          round?.winTeamColor?.toUpperCase() === teamBlue.teamColor
            ? 'win'
            : 'lose',
        typeOfWin: round.winCondition as
          | 'Elimination'
          | 'HostagesRescued'
          | 'HostagesNotRescued'
          | 'BombDefused'
          | 'BombExploded'
          | null,
      } as MatchItemRound
      const roundData = {
        [teamRed.user?.id as string]: yourRound,
        [teamBlue.user?.id as string]: opponentsRound,
      }

      acc.push(roundData)

      return acc
    }, [] as Round[]) || []

  const bombsInfo = rounds.reduce(
    (acc, round) => {
      if (
        round[teamRed.user?.id as string].result === 'win' &&
        round[teamRed.user?.id as string].typeOfWin === 'BombDefused'
      ) {
        acc.teamRed.defused = acc.teamRed.defused + 1
      }
      if (
        round[teamRed.user?.id as string].result === 'win' &&
        round[teamRed.user?.id as string].typeOfWin === 'BombExploded'
      ) {
        acc.teamRed.exploded = acc.teamRed.exploded + 1
      }

      if (
        round[teamBlue.user?.id as string].result === 'win' &&
        round[teamBlue.user?.id as string].typeOfWin === 'BombDefused'
      ) {
        acc.teamBlue.defused = acc.teamBlue.defused + 1
      }
      if (
        round[teamBlue.user?.id as string].result === 'win' &&
        round[teamBlue.user?.id as string].typeOfWin === 'BombExploded'
      ) {
        acc.teamBlue.exploded = acc.teamBlue.exploded + 1
      }

      return acc
    },
    {
      teamRed: { defused: 0, exploded: 0 },
      teamBlue: { defused: 0, exploded: 0 },
    }
  )

  const handlePlayerReport =
    (userId: string) => async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setTabActive('support', true)
      await handleCreateNewAppeal({
        game: 'CS 2',
        reportedUserId: userId,
        reportedMatchId: match.id,
        nextStep: AppealStageTypes.ADD_FILES,
        prevStep: AppealStageTypes.CHOOSE_PLAYER,
        currentStep: AppealStageTypes.CHOOSE_PLAYER_REASON,
        category: EnumClaimReportCategory.ClaimReportCategoryPlayerReport,
      })
    }

  const teamRedLabel = useRelativeTeamLabels
    ? teamRed.user?.id === currentUser.id
      ? t('play.ratingGame.you')
      : t('play.ratingGame.opponent')
    : t('play.ratingGame.player1')

  const teamBlueLabel = useRelativeTeamLabels
    ? teamBlue.user?.id === currentUser.id
      ? t('play.ratingGame.you')
      : t('play.ratingGame.opponent')
    : t('play.ratingGame.player2')

  return (
    <StyledRoot>
      <StyledTeams>
        <StyledTeam>
          <PlayerCardResultCS1x1 score={redScore} player={teamRed.user} />
        </StyledTeam>
        <StyledTeam>
          <PlayerCardResultCS1x1 score={blueScore} player={teamBlue.user} />
        </StyledTeam>
      </StyledTeams>
      <StyledTeamsInfo>
        <StyledTeamInfo>
          {teamRed.teamColor === 'RED' ? (
            <StyledRedTeamTitle>{teamRedLabel}</StyledRedTeamTitle>
          ) : (
            <StyledBlueTeamTitle>{teamRedLabel}</StyledBlueTeamTitle>
          )}
          <StyledReportedPlayerLabel>
            {reportedUserId === teamRed.user?.id && t('judge.judgePage.report')}
          </StyledReportedPlayerLabel>
        </StyledTeamInfo>
        <StyledTeamInfo>
          <StyledReportedPlayerLabel>
            {reportedUserId === teamBlue.user?.id &&
              t('judge.judgePage.report')}
          </StyledReportedPlayerLabel>
          {teamBlue.teamColor === 'RED' ? (
            <StyledRedTeamTitle>{teamBlueLabel}</StyledRedTeamTitle>
          ) : (
            <StyledBlueTeamTitle>{teamBlueLabel}</StyledBlueTeamTitle>
          )}
        </StyledTeamInfo>
      </StyledTeamsInfo>
      <StyledRoundsWrapper>
        <StyledRoundsPart>
          <StyledRoundsLine>
            <StyledRoundsLineTitle $align="left" $color={teamBlue.teamColor}>
              {teamBlueLabel}
            </StyledRoundsLineTitle>
            {rounds.slice(0, 8).map((round, index) => (
              <MatchItem
                key={index}
                round={index + 1}
                result={round[teamBlue.user?.id || ''].result}
                typeOfWin={round[teamBlue.user?.id || ''].typeOfWin}
              />
            ))}
          </StyledRoundsLine>
          <StyledRoundsDivider />
          <StyledRoundsLine>
            <StyledRoundsLineTitle $align="left" $color={teamRed.teamColor}>
              {teamRedLabel}
            </StyledRoundsLineTitle>
            {rounds.slice(0, 8).map((round, index) => (
              <MatchItem
                key={index}
                round={index + 1}
                result={round[teamRed.user?.id || ''].result}
                typeOfWin={round[teamRed.user?.id || ''].typeOfWin}
              />
            ))}
          </StyledRoundsLine>
        </StyledRoundsPart>
        <StyledChangeSidesWrapper>
          <StyledSwitchIcon />

          <Header3>{t('play.ratingGame.changeSides')}</Header3>
          <StyledNumberOfChangedSides>8</StyledNumberOfChangedSides>
        </StyledChangeSidesWrapper>
        <StyledRoundsPart>
          <StyledRoundsLine>
            {rounds.slice(8).map((round, index) => (
              <MatchItem
                key={index}
                round={index + 1}
                result={round[teamRed.user?.id || ''].result}
                typeOfWin={round[teamRed.user?.id || ''].typeOfWin}
              />
            ))}
            <StyledRoundsLineTitle $align="right" $color={teamRed.teamColor}>
              {teamRedLabel}
            </StyledRoundsLineTitle>
          </StyledRoundsLine>
          <StyledRoundsDivider />
          <StyledRoundsLine>
            {rounds.slice(8).map((round, index) => (
              <MatchItem
                key={index}
                round={index + 1}
                result={round[teamBlue.user?.id || ''].result}
                typeOfWin={round[teamBlue.user?.id || ''].typeOfWin}
              />
            ))}
            <StyledRoundsLineTitle $align="right" $color={teamBlue.teamColor}>
              {teamBlueLabel}
            </StyledRoundsLineTitle>
          </StyledRoundsLine>
        </StyledRoundsPart>
      </StyledRoundsWrapper>
      <StyledStatsWrapper>
        <StyledStatsCard>
          {isRedWinner && <StyledWinnerTitle>winner</StyledWinnerTitle>}
          <StyledStatsHeader>
            <StyledWeaponWrapper>
              <StyledWeaponTitles>
                <StyledWeaponName
                  $length={
                    teamRed.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                      ?.weapon?.name?.length || 0
                  }
                >
                  {teamRed.user?.CSMatchProfileStat?.CSBestWeaponProfileStat?.weapon?.name?.replace(
                    '_',
                    ' '
                  ) || ''}
                </StyledWeaponName>
                <StyledInfoText>{t('results.info')}</StyledInfoText>
              </StyledWeaponTitles>
              <StyledWeaponImageWrapper>
                <StyledStatsTitle>{t('results.topWeapon')}</StyledStatsTitle>
                <StyledWeaponImg
                  src={
                    teamRed.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                      ?.weapon?.imgUrl || ''
                  }
                />
              </StyledWeaponImageWrapper>
            </StyledWeaponWrapper>
            <StyledDamagesWrapper>
              <StyledDamageWrapper>
                <Header3>
                  {teamRed.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                    ?.headShotCount || 0}
                </Header3>
                <StyledStatsTitle>{t('results.headshots')}</StyledStatsTitle>
              </StyledDamageWrapper>
              <StyledDamageWrapper>
                <Header3>
                  {teamRed.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                    ?.damage || 0}
                </Header3>
                <StyledStatsTitle>{t('results.damage')}</StyledStatsTitle>
              </StyledDamageWrapper>
            </StyledDamagesWrapper>
          </StyledStatsHeader>
          <StyledStatsFooter>
            {reportable && teamRed.user?.id !== currentUser.id && (
              <StyledPlayerStat $isRight>
                <StyledLike>{t('results.like')}</StyledLike>
                <StyledReport
                  onClick={handlePlayerReport(teamRed.user?.id || '')}
                >
                  {t('results.report')}
                </StyledReport>
              </StyledPlayerStat>
            )}
            <StyledBombsStatContainer>
              <StyledStatsFooterItem>
                <Header2>{bombsInfo.teamRed.defused}</Header2>
                <StyledStatsTitle>{t('results.bombsCleared')}</StyledStatsTitle>
              </StyledStatsFooterItem>
              <StyledStatsFooterItem>
                <Header2>{bombsInfo.teamRed.exploded}</Header2>
                <StyledStatsTitle>{t('results.bombsLaid')}</StyledStatsTitle>
              </StyledStatsFooterItem>
            </StyledBombsStatContainer>
          </StyledStatsFooter>
          <StyledRoundsItems $isWin={isRedWinner}>
            {new Array(20).fill('').map((_, index) => {
              return (
                <StyledRoundsItem
                  key={index}
                  $isActive={(redScore || 0) > index}
                />
              )
            })}
          </StyledRoundsItems>

          <StyledScoreWrapper>
            <StyledKils $isWin={isRedWinner}>{redScore}</StyledKils>
            <StyledScore>{t('results.score')}</StyledScore>
          </StyledScoreWrapper>
        </StyledStatsCard>
        <StyledStatsCard $isRight>
          {isBlueWinner && (
            <StyledWinnerTitle $isRight>winner</StyledWinnerTitle>
          )}
          <StyledStatsHeader $isRight>
            <StyledWeaponWrapper $isRight>
              <StyledWeaponTitles $isRight>
                <StyledWeaponName
                  $length={
                    teamBlue.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                      ?.weapon?.name?.length || 0
                  }
                >
                  {teamBlue.user?.CSMatchProfileStat?.CSBestWeaponProfileStat?.weapon?.name?.replace(
                    '_',
                    ' '
                  ) || ''}
                </StyledWeaponName>
                <StyledInfoText>{t('results.info')}</StyledInfoText>
              </StyledWeaponTitles>
              <StyledWeaponImageWrapper $isRight>
                <StyledStatsTitle>{t('results.topWeapon')}</StyledStatsTitle>
                <StyledWeaponImg
                  $isRight
                  src={
                    teamBlue.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                      ?.weapon?.imgUrl || ''
                  }
                />
              </StyledWeaponImageWrapper>
            </StyledWeaponWrapper>
            <StyledDamagesWrapper>
              <StyledDamageWrapper $isRight>
                <Header3>
                  {teamBlue.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                    ?.headShotCount || 0}
                </Header3>
                <StyledStatsTitle>{t('results.headshots')}</StyledStatsTitle>
              </StyledDamageWrapper>
              <StyledDamageWrapper $isRight>
                <Header3>
                  {teamBlue.user?.CSMatchProfileStat?.CSBestWeaponProfileStat
                    ?.damage || 0}
                </Header3>
                <StyledStatsTitle>{t('results.damage')}</StyledStatsTitle>
              </StyledDamageWrapper>
            </StyledDamagesWrapper>
          </StyledStatsHeader>
          <StyledStatsFooter $isRight>
            {reportable && teamBlue.user?.id !== currentUser.id && (
              <StyledPlayerStat>
                <StyledLike>{t('results.like')}</StyledLike>
                <StyledReport
                  onClick={handlePlayerReport(teamBlue.user?.id || '')}
                >
                  {t('results.report')}
                </StyledReport>
              </StyledPlayerStat>
            )}
            <StyledBombsStatContainer>
              <StyledStatsFooterItem>
                <Header2>{bombsInfo.teamBlue.defused}</Header2>
                <StyledStatsTitle>{t('results.bombsCleared')}</StyledStatsTitle>
              </StyledStatsFooterItem>
              <StyledStatsFooterItem>
                <Header2>{bombsInfo.teamBlue.exploded}</Header2>
                <StyledStatsTitle>{t('results.bombsLaid')}</StyledStatsTitle>
              </StyledStatsFooterItem>
            </StyledBombsStatContainer>
          </StyledStatsFooter>
          <StyledRoundsItems $isRight $isWin={isBlueWinner}>
            {new Array(20).fill('').map((_, index) => {
              return (
                <StyledRoundsItem
                  key={index}
                  $isActive={(blueScore || 0) > index}
                />
              )
            })}
          </StyledRoundsItems>
          <StyledScoreWrapper $isRight>
            <StyledKils $isWin={isBlueWinner}>{blueScore}</StyledKils>
            <StyledScore>{t('results.score')}</StyledScore>
          </StyledScoreWrapper>
        </StyledStatsCard>
      </StyledStatsWrapper>
    </StyledRoot>
  )
}

const StyledRoot = styled.div``

const StyledTeams = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 8px;
`

interface StatSideProps {
  $isRight?: boolean
}

const StyledTeam = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`

const StyledTeamsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 15px;
`

const StyledTeamInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const StyledReportedPlayerLabel = styled.div`
  color: #f44;
  text-shadow: 0px -1px 13px rgba(255, 65, 65, 0.5);
  font-family: Nebula;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`

const StyledRedTeamTitle = styled(SideHeader2)`
  color: #ff4444;
  text-transform: uppercase;
  text-shadow: 0px -1px 13px rgba(255, 65, 65, 0.5);
`

const StyledBlueTeamTitle = styled(SideHeader2)`
  color: #0fa9ff;
  text-transform: uppercase;
  text-shadow: 0px -1px 13px rgba(0, 117, 255, 0.5);
`

interface StyledKilsProps {
  $isWin?: boolean
}

const StyledKils = styled(Header1)<StyledKilsProps>`
  text-transform: uppercase;
  line-height: 100%;
  color: ${({ $isWin }) => ($isWin ? '#2CFF80' : 'rgba(255, 255, 255, 0.15)')};
`

const StyledScore = styled(Header3)`
  color: rgba(255, 255, 255, 0.15);
  text-transform: uppercase;
`

const StyledWeaponImg = styled.img<StatSideProps>`
  position: relative;
  top: -10px;
  max-height: 75px;

  transform: scaleX(${({ $isRight }) => ($isRight ? -1 : 1)});
`

const StyledRoundsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 24px;
  margin-bottom: 40px;
  align-items: center;
`

const StyledRoundsPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledRoundsDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
`

const StyledRoundsLine = styled.div`
  display: flex;
  align-items: center;
  width: 386px;
  gap: 10px;
`

interface StyledRoundsLineTitleProps {
  $align: string
  $color?: string
}

const StyledRoundsLineTitle = styled(Text4)<StyledRoundsLineTitleProps>`
  color: ${({ $color }) => ($color === 'RED' ? '#F44' : '#0FA9FF')};
  text-align: ${({ $align }) => $align};
  text-transform: capitalize;
  width: 100%;
  white-space: nowrap;
`

const StyledChangeSidesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

const StyledNumberOfChangedSides = styled(Header3)`
  color: #2cff80;
`

const StyledSwitchIcon = styled(SwitchIcon)`
  width: 100%;
  position: relative;
  fill: white;
  margin-top: 5px;
`

const StyledStatsWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
`

interface StatSideProps {
  $isRight?: boolean
}

const StyledStatsCard = styled.div<StatSideProps>`
  flex: 1;
  height: 298px;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: ${({ $isRight }) => ($isRight ? '8px 0px ' : '0px 8px')};

  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('${cardBG}');
    transform: scaleX(${({ $isRight }) => ($isRight ? -1 : 1)});
    background-size: cover;
  }
`

const StyledScoreWrapper = styled.div<StatSideProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 24px;
  ${({ $isRight }) =>
    $isRight
      ? css`
          left: 27px;
        `
      : css`
          right: 27px;
        `}
  width: 119px;
`

const StyledWeaponWrapper = styled.div<StatSideProps>`
  display: flex;
  flex-direction: column;
  min-width: 460px;
  ${({ $isRight }) =>
    $isRight
      ? css`
          align-items: end;
        `
      : css`
          align-items: start;
        `}
`

const StyledWeaponTitles = styled.div<StatSideProps>`
  display: flex;
  gap: 12px;
  align-items: start;
  flex-direction: ${({ $isRight }) => ($isRight ? 'row-reverse' : 'row')};
`
interface StyledWeaponNameProps {
  $length?: number
}

const StyledWeaponName = styled(Header2)<StyledWeaponNameProps>`
  text-transform: uppercase;
  line-height: 100%;
  ${({ $length }) =>
    $length &&
    $length > 8 &&
    css`
      font-size: 26px;
    `}
`

const StyledStatsTitle = styled(Text4)`
  color: rgba(255, 255, 255, 0.2);
  white-space: pre-wrap;
  &:first-letter {
    text-transform: uppercase;
  }
`

const StyledStatsHeader = styled.div<StatSideProps>`
  display: flex;

  flex-direction: ${({ $isRight }) => ($isRight ? 'row-reverse' : 'row')};
  padding-top: 18px;
  ${({ $isRight }) =>
    $isRight
      ? css`
          padding-right: 32px;
        `
      : css`
          padding-left: 32px;
        `}
`

const StyledDamageWrapper = styled.div<StatSideProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 10px;

  & div {
    font-weight: 400;
    line-height: 120%;
  }

  ${({ $isRight }) =>
    $isRight
      ? css`
          align-items: end;
        `
      : css`
          align-items: start;
        `}
`

const StyledDamagesWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  gap: 8px;
`

const StyledStatsFooter = styled.div<StatSideProps>`
  display: flex;
  gap: 28px;
  position: absolute;
  bottom: 38px;
  width: calc(100% - 190px);

  ${({ $isRight }) =>
    $isRight
      ? css`
          justify-content: flex-end;
          flex-direction: row-reverse;
          left: 190px;
        `
      : css`
          justify-content: flex-end;
          flex-direction: row;
          right: 190px;
        `}
`

const StyledStatsFooterItem = styled.div`
  display: flex;
  gap: 8px;
  & div {
    line-height: 100%;
  }
`

const StyledBombsStatContainer = styled.div`
  display: flex;
  gap: 16px;
`

const StyledWeaponImageWrapper = styled.div<StatSideProps>`
  display: flex;
  gap: 35px;
  padding-top: 12px;
  flex-direction: ${({ $isRight }) => ($isRight ? 'row-reverse' : 'row')};
`

const StyledInfoText = styled(SideHeader3)`
  color: rgba(255, 255, 255, 0.2);
`

interface StyledRoundsItemsProps {
  $isWin?: boolean
  $isRight?: boolean
}

const StyledRoundsItems = styled.div<StyledRoundsItemsProps>`
  display: flex;
  gap: 4.4px;
  position: absolute;
  bottom: 15.5px;

  ${({ $isWin }) =>
    $isWin
      ? css`
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 40px;
            flex-shrink: 0;
            bottom: 0px;
            border-radius: 50% 50% 0 0;
            background: linear-gradient(
              0deg,
              rgba(0, 181, 94, 0.4) 0%,
              rgba(0, 86, 60, 0) 60%
            );
            z-index: -10;
            border-radius: 50% 50% 0 0;
          }
        `
      : ''}

  z-index: 0;

  ${({ $isRight }) =>
    $isRight
      ? css`
          right: 12px;
          flex-direction: row-reverse;
        `
      : css`
          flex-direction: row;
          left: 12px;
        `}
`

interface StyledRoundsItemProps {
  $isActive?: boolean
}

const StyledRoundsItem = styled.div<StyledRoundsItemProps>`
  height: 4px;
  width: 25px;
  background-color: ${({ $isActive }) =>
    $isActive ? '#2CFF80' : 'rgba(255, 255, 255, 0.15)'};
`

const StyledWinnerTitle = styled.div<StatSideProps>`
  position: absolute;
  bottom: 65px;
  ${({ $isRight }) =>
    $isRight
      ? css`
          left: 190px;
        `
      : css`
          right: 190px;
        `}
  text-align: center;
  font-family: Nebula;
  -webkit-text-fill-color: transparent;
  width: 550px;
  text-align: center;
  font-family: Nebula;
  font-size: 126px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  color: transparent;
  text-shadow:
    0px 2px 0px rgba(255, 255, 255, 0.05),
    0px -2px 0px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
`

const StyledPlayerStat = styled.div<StatSideProps>`
  position: relative;
  z-index: 10;
  display: flex;
  gap: 16px;
  align-items: end;
  margin: 0 30px;
  width: -webkit-fill-available;
  justify-content: ${({ $isRight }) => ($isRight ? 'start' : 'end')};
`

const StyledLike = styled(Text5)`
  color: #2cff80;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: #2cff80;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  transition: 0.2s;
  &:hover {
    color: white;
    &:after {
      background-color: white;
    }
  }
`

const StyledReport = styled(Text5)`
  color: #f44;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: #f44;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  transition: 0.2s;
  &:hover {
    color: white;
    &:after {
      background-color: white;
    }
  }
`

export default MatchResult1x1CSContent
