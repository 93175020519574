import { useEffect, useMemo, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { $currentClaim } from 'stores/judgeStore'
import { Header1, Header3, Text4, Text5 } from 'styles/typography'
import { getMinutesAndSeconds } from 'utils/date'
import JudgeTicketTimerTexture from 'images/judge/JudgeTicketTimerTexture.svg'

const DELAY_IN_MS = 1000

interface JudgeAbleToVoteTimerProps {
  onFinish: () => void
}

const JudgeAbleToVoteTimer = ({ onFinish }: JudgeAbleToVoteTimerProps) => {
  const { t } = useTranslation()

  const claim = useStore($currentClaim)

  const initialProgress = useMemo<number>(() => {
    if (!claim?.myDecision?.availableToVerdictAt) return 0

    const result = dayjs(claim.myDecision.availableToVerdictAt).diff(
      new Date(),
      'seconds'
    )

    return Math.max(0, result)
  }, [claim?.myDecision?.availableToVerdictAt])

  const [remaining, setRemaining] = useState(initialProgress)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemaining((prev) => Math.max(0, prev - 1))
    }, DELAY_IN_MS)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (remaining === 0) {
      onFinish()
    }
  }, [remaining, onFinish])

  if (remaining === 0) return null

  const [minutes, seconds] = getMinutesAndSeconds(remaining)

  return (
    <StyledTimerWrapper>
      <TimerHeaderWrapper>
        <TimerContent>
          <StyledTimerFiller>
            <span>+</span>
            <span>+</span>
          </StyledTimerFiller>
          <TimerTitle> {t('judge.reportModal.timer')}</TimerTitle>
          <TimerDigits>
            <StyledDigitsWrapper>
              <StyledTimeDigits>
                <StyledDigitActive>
                  {remaining < 600 ? (
                    <>
                      <StyledGrayDescription>
                        {minutes[0]}
                      </StyledGrayDescription>
                      <StyledDigit>{minutes[1]}</StyledDigit>
                    </>
                  ) : (
                    <>
                      <StyledDigit>{minutes}</StyledDigit>
                    </>
                  )}
                </StyledDigitActive>
              </StyledTimeDigits>
              <StyledGrayDescription>
                {t('judge.reportModal.minutesFull')}
              </StyledGrayDescription>
            </StyledDigitsWrapper>
            <StyledLine />
            <StyledDigitsWrapper>
              <StyledTimeDigits>
                <StyledDigitActive>
                  <StyledDigit>{seconds}</StyledDigit>
                </StyledDigitActive>
              </StyledTimeDigits>
              <StyledGrayDescription>
                {t('judge.reportModal.secundes')}
              </StyledGrayDescription>
            </StyledDigitsWrapper>
          </TimerDigits>
        </TimerContent>
      </TimerHeaderWrapper>
      <TimerFooterWrapper>
        <TimerDescription>
          {t('judge.reportModal.timerEnableDecision')}
        </TimerDescription>
        <StyledTimerFiller>
          <span>+</span>
          <span>+</span>
        </StyledTimerFiller>
      </TimerFooterWrapper>
    </StyledTimerWrapper>
  )
}

const StyledTimerWrapper = styled.div`
  display: flex;
  padding: 24px;
  width: 300px;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  flex-direction: column;
  justify-content: space-between;
  background: url(${JudgeTicketTimerTexture});
  background-position-y: bottom;
  background-repeat: no-repeat;
`

const TimerContent = styled.div`
  display: flex;
  flex-direction: column;
`

const TimerDescription = styled(Text4)`
  font-weight: 500;
  line-height: 20px;
  color: #ffffff26;
  margin: 0px 0px 12px 0px;
`
const StyledTimerFiller = styled.div`
  color: #ffcd29;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const TimerDigits = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 8px;
`
const TimerTitle = styled(Header3)`
  margin: 10px 0px 0px 0px;
  font-weight: 400;
`
const StyledDigitsWrapper = styled(Text5)`
  width: 100px;
  padding: 19px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  line-height: 60px;
`
const StyledLine = styled.div`
  border-right: 1px solid #ffffff26;
  width: 1px;
  height: 47px;
`
const StyledGrayDescription = styled.div`
  color: #ffffff26;
  line-height: 13px;
`
const StyledTimeDigits = styled(Header1)`
  display: flex;
  font-weight: 400;
`
const StyledDigitActive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`
const StyledDigit = styled.div`
  display: flex;
`
const TimerHeaderWrapper = styled.div``
const TimerFooterWrapper = styled.div``

export default JudgeAbleToVoteTimer
