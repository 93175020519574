import {
  $showQrCodePopup,
  $userConfig,
  $userProfile,
  changeUserConfig,
  toggleQrCodePopup,
} from 'stores/userStore'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { FormError, Header3, Header6, Text3, Text4 } from 'styles/typography'
import { setIsShowExitPopup } from 'stores/appStore'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AudioSlider from 'components/Settings/AudioSlider'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import CloseEyeIcon from 'images/CloseEyeIcon'
import CopyWrapper from 'components/CopyWrapper'
import GoogleLogo from 'images/google-logo.webp'
import Input from 'components/Input'
import Modal from 'components/Modal'
import ModalHeader from 'components/Modals/ModalHeader'
import ModalWrapper from 'components/Modals/ModalWrapper'
import PhoneNumberInput from 'components/PhoneNumberInput'
import QrCodePopup from 'components/QrCodePopup'
import QuestionCircle from 'images/QuestionCircle'
import Toggle from 'components/Toggle'
import styled from 'styled-components'

type LangOptions = 'en' | 'ru'

const LANGUAGES: LangOptions[] = ['en', 'ru']

interface LoginFormDataProps {
  number: string
  password: string
  passwordSecond: string
}

const SettingsPage = () => {
  const { t } = useTranslation()

  const config = useStore($userConfig)
  const user = useStore($userProfile)
  const showQrCodePopup = useStore($showQrCodePopup)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [isShowPasswordSecond, setIsShowPasswordSecond] =
    useState<boolean>(false)

  const VOLUME_OPTIONS = [t('setting.on'), t('setting.off')]

  const onSettingChange = (name: string) => async (value: string | boolean) => {
    await changeUserConfig(name, value)
  }

  const onLngChange = async (lng: LangOptions) => {
    await changeUserConfig('interfaceLanguage', lng.toLocaleLowerCase())
  }

  const onVolumeToggle = async (value: string) => {
    await onVolumeChange(value === t('setting.off') ? 0 : 50)
  }

  const onVolumeChange = async (value: number) => {
    await changeUserConfig('volume', value)
  }

  const toggleLogoutPopup = (visible: boolean) => async () => {
    await setIsShowExitPopup(visible)
  }

  const toggleQrPopup = (visible: boolean) => () => {
    toggleQrCodePopup(visible)
  }

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormDataProps>()
  const password = watch('password')

  const onSubmit: SubmitHandler<LoginFormDataProps> = () => {
    if (!isLoading) {
      setIsLoading(true)

      try {
        // const data = await loginApi({ password, login: email })
        // await login(number, password)
      } catch (e) {
        // const { error } = e as ApiError
        // console.log(error)
        setError(t('auth.wrongLoginOrPassword'))
      }

      setIsLoading(false)
    }
  }

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  // const handleChangeVersion = async (value: Option[]) => {
  //   await onSettingChange('applicationVersion')(value[0].id)
  // }

  const handleShowPasswordSecond = () => {
    setIsShowPasswordSecond(!isShowPasswordSecond)
  }

  return (
    <ModalWrapper>
      <StyledModalHeader>
        <ModalHeader modalType="settings" />
      </StyledModalHeader>
      {/* <ModalContent> */}
      <StyledRoot>
        <StyledTitleWrapper>
          <StyledTitle>{t('setting.langTitle')}</StyledTitle>
          <Toggle
            items={LANGUAGES}
            padding="3px 19px"
            onChange={onLngChange}
            value={config?.interfaceLanguage as LangOptions}
          />
        </StyledTitleWrapper>
        <SettingsBlock>
          <StyledTitleWrapper>
            <StyledTitle>{t('setting.general')}</StyledTitle>
          </StyledTitleWrapper>
          <StyledCheckboxes>
            <StyledCheckboxWrapper>
              <Checkbox
                isActive={Boolean(config?.closeTheTray)}
                onChange={onSettingChange('closeTheTray')}
              >
                <StyledLabel>{t('setting.trayLabel')}</StyledLabel>
              </Checkbox>
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
              <Checkbox
                isActive={Boolean(config?.startingAtWindowsStartup)}
                onChange={onSettingChange('startingAtWindowsStartup')}
              >
                <StyledLabel>{t('setting.startingLabel')}</StyledLabel>
              </Checkbox>
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
              <Checkbox
                disabled
                isActive={false}
                onChange={onSettingChange('gameNotification')}
              >
                <StyledLabel>{t('setting.gamesNotifications')}</StyledLabel>
              </Checkbox>
              <StyledCheckboxWrapperDescr $visible={true}>
                {t('setting.gamesNotificationsDescr')}
              </StyledCheckboxWrapperDescr>
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
              <Checkbox
                disabled
                isActive={false}
                onChange={onSettingChange('eventNotification')}
              >
                <StyledLabel>{t('setting.eventsNotifications')}</StyledLabel>
              </Checkbox>
              <StyledCheckboxWrapperDescr $visible={true}>
                {t('setting.eventsNotificationsDescr')}
              </StyledCheckboxWrapperDescr>
            </StyledCheckboxWrapper>
          </StyledCheckboxes>
        </SettingsBlock>
        <SettingsBlock>
          <StyledTitleWrapper>
            <StyledTitle>{t('setting.personal')}</StyledTitle>
            <StyledTitle>
              {t('setting.profileId')}:
              <CopyWrapper textToCopy={user?.externalId || ''}>
                <StyledId> #{user?.externalId || ''}</StyledId>
              </CopyWrapper>
            </StyledTitle>
          </StyledTitleWrapper>
          <StyledAccountWrapper>
            <StyledContainer onSubmit={handleSubmit(onSubmit)}>
              <StyledForm>
                <Controller
                  name="number"
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <PhoneNumberInput
                      disabled
                      id={'number'}
                      label={t('auth.numberField.label')}
                      error={
                        errors.number && t('auth.numberField.errors.required')
                      }
                      {...field}
                      value={user?.phoneNumber}
                    />
                  )}
                />
                <StyledPasswrodWrapper>
                  <Controller
                    defaultValue=""
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        disabled
                        id={'password'}
                        label={t('auth.passwordField.label')}
                        type={isShowPassword ? 'text' : 'password'}
                        onClickRightDeacorator={handleShowPassword}
                        placeholder={t('auth.passwordField.placeholder') || ''}
                        rightDecorator={
                          isShowPassword ? <CloseEyeIcon /> : <CloseEyeIcon />
                        }
                        error={
                          errors.password &&
                          t('auth.passwordField.errors.required')
                        }
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    defaultValue=""
                    control={control}
                    name="passwordSecond"
                    rules={{
                      required: true,
                      validate: { correct: (v) => v === password },
                    }}
                    render={({ field }) => (
                      <Input
                        id={'passwordSecond'}
                        type={isShowPasswordSecond ? 'text' : 'password'}
                        onClickRightDeacorator={handleShowPasswordSecond}
                        placeholder={
                          t('registation.secondPasswordField.placeholder') || ''
                        }
                        error={
                          errors.passwordSecond &&
                          t('registation.secondPasswordField.errors.match')
                        }
                        rightDecorator={
                          isShowPasswordSecond ? (
                            <CloseEyeIcon />
                          ) : (
                            <CloseEyeIcon />
                          )
                        }
                        {...field}
                      />
                    )}
                  />
                </StyledPasswrodWrapper>
                {error && <FormError>{error || ` `}</FormError>}
                <StyledButtonWithWarningWrapper>
                  <StyledInfoWarning>
                    {t('setting.haveAlreadyfactorError')}
                    <QuestionCircle />
                  </StyledInfoWarning>
                  <StyledButton
                    type="button"
                    isLoading={isLoading}
                    onClick={toggleQrPopup(true)}
                    disabled={!!user?.isTotpRequired}
                  >
                    <StyledButtonText>
                      {t('setting.connectGoogle')}
                    </StyledButtonText>
                    <StyledGoogleLogo />
                  </StyledButton>
                </StyledButtonWithWarningWrapper>
              </StyledForm>
            </StyledContainer>
            <StyledContainer>
              <StyledInput
                disabled
                id={'email'}
                label={'E-mail'}
                placeholder={t('setting.enterEmail') || ''}
                error={
                  errors.password && t('auth.passwordField.errors.required')
                }
              />
              <StyledButtonWithWarningWrapper>
                <StyledInfoWarning>
                  {t('setting.youLoseProfileInformation')}
                  <QuestionCircle />
                </StyledInfoWarning>
                <StyledButton
                  type="button"
                  isLoading={isLoading}
                  onClick={toggleLogoutPopup(true)}
                >
                  <StyledButtonText>{t('setting.logout')}</StyledButtonText>
                </StyledButton>
              </StyledButtonWithWarningWrapper>
            </StyledContainer>
            <Modal isOpen={showQrCodePopup} onClose={toggleQrPopup(false)}>
              <QrCodePopup />
            </Modal>
          </StyledAccountWrapper>
        </SettingsBlock>
        <SettingsBlock>
          <StyledTitleWrapper>
            <StyledTitle>{t('setting.audioTitle')}</StyledTitle>
            <Toggle
              padding="3px 19px"
              items={VOLUME_OPTIONS}
              onChange={onVolumeToggle}
              value={config?.volume ? t('setting.on') : t('setting.off')}
            />
          </StyledTitleWrapper>
          <StyledAudioSliderWrapper>
            <AudioSlider
              onVolumeChange={onVolumeChange}
              initialValue={config?.volume || 0}
            />
          </StyledAudioSliderWrapper>
        </SettingsBlock>
      </StyledRoot>
      {/* </ModalContent> */}
    </ModalWrapper>
  )
}

const StyledModalHeader = styled.div`
  padding: 20px 20px 20px 30px;
`

const StyledTitle = styled(Header3)`
  text-transform: uppercase;
  padding-bottom: 8px;
`
const StyledCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
interface LabelDescrProps {
  $visible: boolean
}
const StyledCheckboxWrapperDescr = styled(Text4)<LabelDescrProps>`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
  padding-left: 37px;
  margin-top: 10px;
  line-height: 125%;
  opacity: 0.25;
`

const StyledLabel = styled(Text3)`
  line-height: 100%;
`

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const SettingsBlock = styled.div`
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.03);
  padding: 24px;
`

const StyledRoot = styled.div`
  padding: 0px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const StyledId = styled.span`
  color: #ffcd29;
`

const StyledAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 120px;
`

const StyledContainer = styled.form`
  width: 756px;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: space-between;
`

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`

const StyledPasswrodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledButton = styled(Button)`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`

const StyledInput = styled(Input)`
  width: 100%;
`

const StyledButtonText = styled(Header6)`
  text-transform: uppercase;
`

const StyledGoogleLogo = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${GoogleLogo});
  background-size: contain;
  position: absolute;
  right: 12px;
  top: 9px;
`

const StyledInfoWarning = styled(Text4)`
  color: #f44;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
`

const StyledButtonWithWarningWrapper = styled.div`
  width: 100%;
`

const StyledAudioSliderWrapper = styled.div`
  margin-top: 32px;
`

export default SettingsPage
