import { Pie, PieChart, Sector } from 'recharts'
import styled from 'styled-components'

const data = [{ value: 1, name: 'approved' }]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    value,
    payload,
    midAngle,
    endAngle,
    startAngle,
    innerRadius,
    outerRadius,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)

  const mx = cx + (outerRadius - 5) * cos - 10
  const my = cy + (outerRadius - 5) * sin - 10
  const ex = mx + (cos >= 0 ? 1 : -1) * 18
  const ey = my

  return (
    <g>
      <Sector
        z={1}
        cx={cx}
        cy={cy}
        cornerRadius={15}
        endAngle={endAngle - 2}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle + 2}
        fill={getColor(payload.name)}
      />
      {payload.name !== 'available' && (
        <foreignObject y={ey} x={ex} width={40} height={40}>
          <div
            className="text"
            style={{
              color: getColor(payload.name),
            }}
          >
            {value}
          </div>
        </foreignObject>
      )}
    </g>
  )
}

const getColor = (name: string) => {
  switch (name) {
    case 'declined':
      return '#FFCD29'
    case 'finished':
      return '#2CFF80'
    case 'available':
    default:
      return 'transparent'
  }
}

const renderActiveBackShape = () => {
  return (
    <g>
      <svg
        x={115}
        y={115}
        width="171"
        fill="none"
        height="174"
        viewBox="0 0 171 174"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="white"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill-opacity="0.15"
          d="M85.2449 143.798C117.3 143.798 143.287 117.812 143.287 85.7566C143.287 53.701 117.3 27.7147 85.2449 27.7147C53.1892 27.7147 27.203 53.701 27.203 85.7566C27.203 117.812 53.1892 143.798 85.2449 143.798ZM85.2449 169.513C131.502 169.513 169.001 132.014 169.001 85.7566C169.001 39.4991 131.502 2 85.2449 2C38.9874 2 1.48828 39.4991 1.48828 85.7566C1.48828 132.014 38.9874 169.513 85.2449 169.513Z"
        />
        <path
          stroke="white"
          stroke-opacity="0.1"
          stroke-width="1.46941"
          d="M142.552 85.7566C142.552 117.406 116.895 143.064 85.2449 143.064C53.595 143.064 27.9377 117.406 27.9377 85.7566C27.9377 54.1067 53.595 28.4494 85.2449 28.4494C116.895 28.4494 142.552 54.1067 142.552 85.7566ZM85.2449 170.248C131.908 170.248 169.736 132.42 169.736 85.7566C169.736 39.0933 131.908 1.26529 85.2449 1.26529C38.5816 1.26529 0.753574 39.0933 0.753574 85.7566C0.753574 132.42 38.5816 170.248 85.2449 170.248Z"
        />
        <path
          stroke-opacity="0.2"
          stroke-width="1.46941"
          stroke="url(#paint0_linear_9403_254051)"
          d="M142.552 85.7566C142.552 117.406 116.895 143.064 85.2449 143.064C53.595 143.064 27.9377 117.406 27.9377 85.7566C27.9377 54.1067 53.595 28.4494 85.2449 28.4494C116.895 28.4494 142.552 54.1067 142.552 85.7566ZM85.2449 170.248C131.908 170.248 169.736 132.42 169.736 85.7566C169.736 39.0933 131.908 1.26529 85.2449 1.26529C38.5816 1.26529 0.753574 39.0933 0.753574 85.7566C0.753574 132.42 38.5816 170.248 85.2449 170.248Z"
        />
        <path
          stroke-opacity="0.5"
          stroke-width="1.46941"
          stroke="url(#paint1_radial_9403_254051)"
          d="M142.552 85.7566C142.552 117.406 116.895 143.064 85.2449 143.064C53.595 143.064 27.9377 117.406 27.9377 85.7566C27.9377 54.1067 53.595 28.4494 85.2449 28.4494C116.895 28.4494 142.552 54.1067 142.552 85.7566ZM85.2449 170.248C131.908 170.248 169.736 132.42 169.736 85.7566C169.736 39.0933 131.908 1.26529 85.2449 1.26529C38.5816 1.26529 0.753574 39.0933 0.753574 85.7566C0.753574 132.42 38.5816 170.248 85.2449 170.248Z"
        />
        <circle
          r="72.3686"
          cx="84.8768"
          cy="85.3891"
          stroke="white"
          stroke-opacity="0.15"
          stroke-width="1.46941"
        />
        <defs>
          <filter
            x="7.56041"
            y="94.4066"
            width="160.555"
            height="79.278"
            id="filter0_d_9403_254051"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              type="matrix"
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="0.963238" />
            <feGaussianBlur stdDeviation="2.88972" />
            <feComposite operator="out" in2="hardAlpha" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.170833 0 0 0 0 1 0 0 0 0 0.5025 0 0 0 0.4 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_9403_254051"
            />
            <feBlend
              mode="normal"
              result="shape"
              in="SourceGraphic"
              in2="effect1_dropShadow_9403_254051"
            />
          </filter>
          <linearGradient
            x1="85.2449"
            y1="9.13202"
            x2="85.2449"
            y2="162.381"
            id="paint0_linear_9403_254051"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="0.25" stop-opacity="0" stop-color="white" />
          </linearGradient>
          <radialGradient
            r="1"
            cx="0"
            cy="0"
            id="paint1_radial_9403_254051"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(84.9806 169.229) rotate(-90) scale(59.1068 102.359)"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-opacity="0" stop-color="white" />
          </radialGradient>
        </defs>
      </svg>
    </g>
  )
}

interface JudgeStatPieProps {
  finished: number
  declined: number
  available: number
}

const JudgeStatPie = ({ finished, declined, available }: JudgeStatPieProps) => {
  return (
    <StyledPieChartWrapper>
      <StyledPieChart width={400} height={400}>
        <Pie
          cx="50%"
          cy="50%"
          data={data}
          dataKey="value"
          activeIndex={0}
          activeShape={renderActiveBackShape}
        />
        <Pie
          cx="50%"
          cy="50%"
          data={[
            { value: available, name: 'available' },
            { value: declined, name: 'declined' },
            { value: finished, name: 'finished' },
          ]}
          dataKey="value"
          activeIndex={0}
          innerRadius={63}
          outerRadius={79}
          activeShape={renderActiveShape}
          inactiveShape={renderActiveShape}
        />
      </StyledPieChart>
    </StyledPieChartWrapper>
  )
}

export default JudgeStatPie

const StyledPieChartWrapper = styled.div`
  width: 200px;
`

const StyledPieChart = styled(PieChart)`
  margin-left: -60%;
  & .text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    padding: 3px 6px;
    border-radius: 3px 0px;
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    background: var(--Button-BaseButtonColor, rgba(0, 0, 0, 0.2));
    backdrop-filter: blur(11.5px);

    font-family: 'Ruberoid';
    font-size: 16px;
    font-weight: bold;
  }
`
