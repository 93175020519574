import { useMemo } from 'react'

import { DtoClaimPreview, EnumClaimStatus } from 'api/schemas/judgeApi'

const useFilteredClaims = (
  claims: DtoClaimPreview[],
  searchFilter: string,
  statusFilter: EnumClaimStatus | undefined,
  decisionFilter: boolean | null | undefined
) =>
  useMemo(
    () =>
      claims.filter((ticket) => {
        const formattedSearchFilter = searchFilter.toLowerCase()

        if (
          formattedSearchFilter &&
          !ticket.externalId?.toLowerCase().includes(formattedSearchFilter) &&
          !ticket.reportedUser?.name
            ?.toLowerCase()
            .includes(formattedSearchFilter)
        )
          return false

        if (statusFilter && ticket.status !== statusFilter) return false

        if (decisionFilter !== undefined) {
          return typeof decisionFilter === 'boolean'
            ? decisionFilter === ticket.isGuiltyVerdict
            : ticket.isGuiltyVerdict === null
        }

        return true
      }),
    [claims, searchFilter, statusFilter, decisionFilter]
  )

export default useFilteredClaims
