import {
  $chatOpen,
  $lastMessageForFastShow,
  $tabActive,
  TabActive,
  setChatOpen,
  setTabActive,
} from 'stores/chatStores/chatStore'
import {
  $chatsHaveUnreadMessage,
  getPartnerChat,
} from 'stores/chatStores/singleChat'
import {
  $groupChatsInfo,
  $groupsChatsHaveUnreadMessage,
} from 'stores/chatStores/groupChats'
import { $haveNewNotification } from 'stores/chatStores/notificationsStore'
import { Resizable } from 're-resizable'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import Arrow from 'images/chat/Arrow'
import ChatIcon from 'images/chat/ChatIcon'
import ChatMenu from 'components/Chat/ChatMenu'
import ChatWrapper from 'components/Chat/ChatWrapper'
import Counter from 'components/Counter'
import FastMessagesBlock from 'components/Chat/FastMessagesBlock'
import Invite from 'images/chat/Invite'
import InviteWrapper from 'components/Chat/InviteWrapper'
import Party from 'images/chat/Party'
import PartyWrapper from 'components/Chat/PartyWrapper'
import Support from 'images/chat/Support'
import SupportWrapper from 'components/Chat/SupportWrapper'
import styled from 'styled-components'

interface StyledChatWrapperProps {
  $isOpen: boolean
  $hasLastMessage?: boolean
}
const StyledChatWrapper = styled.div<StyledChatWrapperProps>`
  height: ${({ $isOpen, $hasLastMessage }) =>
    $isOpen ? 'auto' : $hasLastMessage ? '75px' : '53px'};
  justify-content: start;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
  z-index: 10;
  transition: 0.3s;
`

const StyledChatConteiner = styled.div<StyledChatWrapperProps>`
  display: flex;
  gap: 10px;
  /* background: #1d1e22; */
  background: var(
    --FriendList-Color,
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    ),
    rgba(29, 30, 34, 1)
  );
  width: 1130px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  /* backdrop-filter: blur(4px); */

  height: ${({ $isOpen, $hasLastMessage }) =>
    $isOpen ? '100%' : $hasLastMessage ? '75px' : '53px'};
  transition: 0.3s;
  justify-content: start;
  cursor: ${({ $isOpen }) => ($isOpen ? 'default' : 'pointer')};
  transition: 0.3s;
`

const StyledResizable = styled(Resizable)``

const StyledChatArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const StyledChatAreaTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-height: 40px;
  cursor: pointer;
  width: 100%;
`
interface StyledLastMessageStylesProps {
  $hasLastMessage?: boolean
}

const StyledChatClosed = styled.div<StyledLastMessageStylesProps>`
  display: flex;
  width: 100%;
  padding-left: 22px;
  padding-top: ${({ $hasLastMessage }) => ($hasLastMessage ? '22px' : '0')};

  position: relative;
  align-items: center;
`
const StyledChatClosedButtons = styled.div`
  display: flex;

  height: 100%;
  gap: 33px;
  align-items: center;
`
const StyledChatClosedButton = styled.div`
  position: relative;
  cursor: pointer;
`

const StyledIconWrapper = styled.div`
  position: relative;
`
interface StyledArrowProps extends StyledLastMessageStylesProps {
  $isChatOpen: boolean
}

const StyledArrow = styled(Arrow)<StyledArrowProps>`
  position: absolute;
  left: calc(50% - 12px);
  cursor: pointer;
  min-height: 40px;
  transform: rotate(${({ $isChatOpen }) => ($isChatOpen ? '0' : '180deg')});
  top: ${({ $hasLastMessage }) => ($hasLastMessage ? '-8px' : '0')};
  width: 24px;
  transition: 0.3s;
`

const Chat: React.FC = () => {
  const chatOpen = useStore($chatOpen)
  const tabActive = useStore($tabActive)
  const lastMessage = useStore($lastMessageForFastShow)
  const haveUnreadMessage = useStore($chatsHaveUnreadMessage)
  const groupsChatsHaveUnreadMessage = useStore($groupsChatsHaveUnreadMessage)
  const haveNewNotification = useStore($haveNewNotification)
  const groupChatsInfo = useStore($groupChatsInfo)
  const [size, setSize] = useState(460)

  const toggleChat = () => {
    setChatOpen(!chatOpen)
  }

  const openChat = (chat: TabActive, id?: string) => () => {
    setTabActive(chat)
    setChatOpen(true)
    if (id) {
      void getPartnerChat(id)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onResize = (e: any, direction: any, ref: any, d: any) => {
    setSize(size + d.height)
  }

  return (
    <StyledChatWrapper
      $hasLastMessage={Boolean(lastMessage)}
      $isOpen={chatOpen}
    >
      {chatOpen ? (
        <StyledResizable
          minHeight={'460px'}
          minWidth={'1130px'}
          maxWidth={'1130px'}
          maxHeight={'1000px'}
          onResizeStop={onResize}
          size={{ width: 1130, height: size }}
        >
          <StyledChatConteiner $isOpen={chatOpen}>
            <ChatMenu />
            <StyledChatArea>
              <StyledChatAreaTop onClick={toggleChat}></StyledChatAreaTop>
              {tabActive === 'chat' && <ChatWrapper />}
              {tabActive === 'party' ? <PartyWrapper /> : ''}
              {tabActive === 'invite' && <InviteWrapper />}
              {tabActive === 'support' && <SupportWrapper />}
              <StyledArrow onClick={toggleChat} $isChatOpen={chatOpen} />
            </StyledChatArea>
          </StyledChatConteiner>
        </StyledResizable>
      ) : (
        <StyledChatConteiner
          $hasLastMessage={Boolean(lastMessage)}
          onClick={toggleChat}
          $isOpen={chatOpen}
        >
          <StyledChatClosed $hasLastMessage={Boolean(lastMessage)}>
            <StyledChatClosedButtons>
              <StyledChatClosedButton onClick={openChat('chat')}>
                <StyledIconWrapper>
                  <ChatIcon />
                  <Counter
                    withoutCount
                    position={'top-right'}
                    value={haveUnreadMessage}
                  />
                </StyledIconWrapper>
              </StyledChatClosedButton>
              {(groupChatsInfo.party || groupChatsInfo.lobby) && (
                <StyledChatClosedButton onClick={openChat('party')}>
                  <StyledIconWrapper>
                    <Party />
                    <Counter
                      withoutCount
                      position={'top-right'}
                      value={groupsChatsHaveUnreadMessage}
                    />
                  </StyledIconWrapper>
                </StyledChatClosedButton>
              )}
              <StyledChatClosedButton>
                <StyledIconWrapper onClick={openChat('invite')}>
                  <Invite />
                  <Counter
                    withoutCount
                    position={'top-right'}
                    value={haveNewNotification}
                  />
                </StyledIconWrapper>
              </StyledChatClosedButton>
              <StyledChatClosedButton onClick={openChat('support')}>
                <StyledIconWrapper>
                  <Support />
                </StyledIconWrapper>
              </StyledChatClosedButton>
            </StyledChatClosedButtons>
            <FastMessagesBlock openChat={openChat} />
            <StyledArrow
              $hasLastMessage={Boolean(lastMessage)}
              onClick={toggleChat}
              $isChatOpen={chatOpen}
            />
          </StyledChatClosed>
        </StyledChatConteiner>
      )}
    </StyledChatWrapper>
  )
}

export default Chat
