import styled from 'styled-components'

import TicketDate from 'components/Judge/ClaimInfo/TicketDate'
import TicketInfo from 'components/Judge/ClaimInfo/TicketInfo'
import TicketStatistic from 'components/Judge/ClaimInfo/TicketStatistic'
import VoteProcess from 'components/Judge/ClaimInfo/VoteProcess'

interface JudgeClaimInfoProps {
  totalMinutesToDecide: number
  remainingMinutesToDecide: number
}

const JudgeClaimInfo = ({
  totalMinutesToDecide,
  remainingMinutesToDecide,
}: JudgeClaimInfoProps) => {
  return (
    <StyledWrapper>
      <VoteProcess
        totalMinutesToDecide={totalMinutesToDecide}
        remainingMinutesToDecide={remainingMinutesToDecide}
      />
      <StyledMainContent>
        <TicketDate />
        <StyledData>
          <TicketInfo />
          <TicketStatistic />
        </StyledData>
      </StyledMainContent>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-left: 20px;
  margin-top: 28px;
  width: 1588px;
`

const StyledMainContent = styled.div`
  display: flex;
  width: 100%;
  height: 546px;
  margin: 0px 0px 40px 0px;
  gap: 15px;
`

const StyledData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 18px;
`

export default JudgeClaimInfo
