/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoAttachmentCreate {
  attachmentId?: string
  attachmentName?: string
  attachmentUrl?: string
}

export interface DtoChat {
  claimId?: string
  createdAt?: string
  haveUnreadMessage?: boolean
  id?: string
  isGroup?: boolean
  /** only group */
  lobbyId?: string
  messages?: DtoMessage[]
  participants?: DtoChatParticipant[]
  partyId?: string
}

export interface DtoChatParticipant {
  chatId?: string
  deletedAt?: string
  id?: string
  imgUrl?: string
  name?: string
  online?: boolean
  role?: string
}

export interface DtoMessage {
  attachments?: DtoMessageAttachment[]
  author?: DtoUser
  authorId?: string
  createdAt?: string
  id?: string
  isSystem?: boolean
  /** (INIT, FINALLY, TO_DELETE, ESTIMATE) */
  status?: string
  text?: string
  user?: DtoUser
  userId?: string
}

export interface DtoMessageAttachment {
  attachmentId?: string
  attachmentName?: string
  attachmentUrl?: string
  createdAt?: string
  id?: string
  messageId?: string
}

export interface DtoMessageRequest {
  attachments?: DtoAttachmentCreate[]
  text?: string
}

export interface DtoUser {
  id?: string
  imgUrl?: string
  name?: string
  online?: boolean
  role?: string
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export namespace Chats {
  /**
   * @description get Chat by claim id
   * @tags Chat
   * @name GetClaim
   * @summary get Chat by claim id
   * @request GET:/chats/claim/{claimId}
   */
  export namespace GetClaim {
    export type RequestParams = {
      /** Claim ID */
      claimId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoChat
    }
  }

  /**
   * @description get Friends Chats
   * @tags Chat
   * @name GetFriends
   * @summary get Friends Chats
   * @request GET:/chats/friends
   */
  export namespace GetFriends {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoChat[]
    }
  }

  /**
   * @description get Group Chats
   * @tags Chat
   * @name GetGroups
   * @summary get Group Chats
   * @request GET:/chats/groups
   */
  export namespace GetGroups {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoChat[]
    }
  }

  /**
   * @description get Chat by friend id
   * @tags Chat
   * @name GetPartner
   * @summary get Chat by friend id
   * @request GET:/chats/partner/{partnerId}
   */
  export namespace GetPartner {
    export type RequestParams = {
      /** Friend ID */
      partnerId: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoChat
    }
  }

  /**
   * @description get Support Chats
   * @tags Chat
   * @name GetSupport
   * @summary get Support Chats
   * @request GET:/chats/support
   */
  export namespace GetSupport {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoChat[]
    }
  }

  /**
   * @description Get chat by id
   * @tags Chat
   * @name GetChats
   * @summary get Chat
   * @request GET:/chats/{id}
   */
  export namespace GetChats {
    export type RequestParams = {
      /** Chat ID */
      id: string
    }
    export type RequestQuery = {
      /** last message dateTime which get another messages */
      fromCreatedAt?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoChat
    }
  }

  /**
   * @description send message in chat
   * @tags Chat
   * @name PostMessage
   * @summary send message in chat
   * @request POST:/chats/{id}/message
   */
  export namespace PostMessage {
    export type RequestParams = {
      /** Chat ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = DtoMessageRequest
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Read messages from chat
   * @tags Chat
   * @name PostRead
   * @summary Read messages from chat
   * @request POST:/chats/{id}/read
   */
  export namespace PostRead {
    export type RequestParams = {
      /** Chat ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/chat/api/v1' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title Chat API
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/chat/api/v1
 * @contact Mikhel Alexander
 *
 * This is chat api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  chats = {
    /**
     * @description get Chat by claim id
     *
     * @tags Chat
     * @name GetClaim
     * @summary get Chat by claim id
     * @request GET:/chats/claim/{claimId}
     */
    getClaim: (claimId: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoChat
        },
        any
      >({
        path: `/chats/claim/${claimId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get Friends Chats
     *
     * @tags Chat
     * @name GetFriends
     * @summary get Friends Chats
     * @request GET:/chats/friends
     */
    getFriends: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoChat[]
        },
        any
      >({
        path: `/chats/friends`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get Group Chats
     *
     * @tags Chat
     * @name GetGroups
     * @summary get Group Chats
     * @request GET:/chats/groups
     */
    getGroups: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoChat[]
        },
        any
      >({
        path: `/chats/groups`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get Chat by friend id
     *
     * @tags Chat
     * @name GetPartner
     * @summary get Chat by friend id
     * @request GET:/chats/partner/{partnerId}
     */
    getPartner: (partnerId: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoChat
        },
        any
      >({
        path: `/chats/partner/${partnerId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get Support Chats
     *
     * @tags Chat
     * @name GetSupport
     * @summary get Support Chats
     * @request GET:/chats/support
     */
    getSupport: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoChat[]
        },
        any
      >({
        path: `/chats/support`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get chat by id
     *
     * @tags Chat
     * @name GetChats
     * @summary get Chat
     * @request GET:/chats/{id}
     */
    getChats: (
      id: string,
      query?: {
        /** last message dateTime which get another messages */
        fromCreatedAt?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoChat
        },
        any
      >({
        path: `/chats/${id}`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description send message in chat
     *
     * @tags Chat
     * @name PostMessage
     * @summary send message in chat
     * @request POST:/chats/{id}/message
     */
    postMessage: (id: string, request: DtoMessageRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/chats/${id}/message`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Read messages from chat
     *
     * @tags Chat
     * @name PostRead
     * @summary Read messages from chat
     * @request POST:/chats/{id}/read
     */
    postRead: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, any>({
        path: `/chats/${id}/read`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  }
}
