import styled, { css } from 'styled-components'

import { DtoMatch, DtoMatchParticipant } from 'api/schemas/matchApi'
import { Header5, Header6 } from 'styles/typography'
import {
  PlayerExtraCardType,
  PlayerRenderer,
} from 'components/MatchResults/MatchResultsScoreboard'
import AvatarWithRang from 'components/AvatarWithRang'
import Coin from 'images/Coin'
import MVPlostIcon from 'images/MVPlost.svg'
import MVPwonIcon from 'images/MVPwon.svg'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'
import reportedPlayerIcon from 'images/reportedPlayerIcon.svg'
import round from 'utils/round'

interface TeamParticipantsProps {
  match: DtoMatch
  teamColor: 'RED' | 'BLUE'
  renderPlayerActions?: PlayerRenderer
  playerExtraCardType: PlayerExtraCardType
  reportedUserId?: string
}

const TeamParticipants = ({
  match,
  teamColor,
  reportedUserId,
  renderPlayerActions,
  playerExtraCardType,
}: TeamParticipantsProps) => {
  if (!match?.participants || match?.participants?.length === 0) {
    return null
  }

  const winner = match?.CSMatchResult?.winTeamColor

  const MVP = getMVP(
    match.participants.filter(
      (participant) => participant.teamColor === teamColor
    )
  )

  return (
    <>
      {match?.participants
        ?.filter((item) => item.teamColor === teamColor)
        .reduce(
          (acc, item) =>
            item.user?.id === MVP.user?.id ? [item, ...acc] : [...acc, item],
          [] as DtoMatchParticipant[]
        )
        .map((participant, index) => {
          const { user } = participant
          if (!user) return

          const { CSMatchProfileStat, ...item } = user
          const isWin = winner === teamColor
          const isMVP = MVP.user?.id === item.id
          const isWinMVP = isWin && isMVP

          const renderExtraCard = () => {
            switch (playerExtraCardType) {
              case 'mvp':
                return isMVP && <StyledMVPLabel $isWin={winner === teamColor} />
              case 'report':
                return reportedUserId === user.id && <StyledReportLabel />
              default:
                return null
            }
          }

          return (
            <StyledTableRow
              key={index}
              isWinMVP={playerExtraCardType === 'mvp' && isWinMVP}
            >
              {renderExtraCard()}
              <StyledTableRowMainItem>
                <StyledPlayerInfo>
                  <WithUserMenu profile={item}>
                    <StyledMVPPlayer>
                      <AvatarWithRang avatar={item.imgUrl} />
                      <StyledMVPPlayerInfo>
                        <StyledNickname>{item.name}</StyledNickname>
                        {renderPlayerActions?.(participant)}
                      </StyledMVPPlayerInfo>
                    </StyledMVPPlayer>
                  </WithUserMenu>
                </StyledPlayerInfo>
                <div>{CSMatchProfileStat?.kill}</div>
                <div>{CSMatchProfileStat?.dead}</div>
                <div>{CSMatchProfileStat?.assist}</div>
                <StyledChangedRaiting
                  $isNegative={Number(CSMatchProfileStat?.KDDiff) < 0}
                >
                  {round(Number(CSMatchProfileStat?.KDDiff))}
                </StyledChangedRaiting>
                <div>{round(CSMatchProfileStat?.KD, 2)}</div>
                <div>{round(CSMatchProfileStat?.ADR)}</div>
                <div>{round(CSMatchProfileStat?.HS)}</div>
                <div>{round(CSMatchProfileStat?.KAST)}</div>
              </StyledTableRowMainItem>
              <StyledTableItem>{round(CSMatchProfileStat?.EF)}</StyledTableItem>
              <StyledTableItem>
                {round(CSMatchProfileStat?.score)}
              </StyledTableItem>
              <StyledTableItem>
                {Number(round(CSMatchProfileStat?.ratingDiff)) < 0 ? '-' : '+'}
                {Math.abs(Number(round(CSMatchProfileStat?.ratingDiff)))}
              </StyledTableItem>
              <StyledTableItem>
                <StyledCoin />{' '}
                {winner === teamColor ? `+${match.bet}` : `-${match.bet}`}
              </StyledTableItem>
            </StyledTableRow>
          )
        })}
    </>
  )
}

const getMVP = (users: DtoMatchParticipant[]) => {
  return users.reduce((acc, participant) => {
    if (
      (acc.user?.CSMatchProfileStat?.kill || 0) <
      (participant.user?.CSMatchProfileStat?.kill || 0)
    ) {
      acc = participant
    }
    return acc
  }, users[0])
}

interface StyledChangedRaiting {
  $isNegative?: boolean
}

const StyledChangedRaiting = styled.div<StyledChangedRaiting>`
  color: ${({ $isNegative }) =>
    $isNegative ? 'rgba(255, 68, 68, 1)' : 'rgba(255, 205, 41, 1)'};
`

const StyledPlayerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`

const StyledMVPPlayer = styled.div`
  display: flex;
  gap: 16px;
`

const StyledMVPPlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`

const StyledNickname = styled(Header6)`
  text-align: left;
`

const winMVPcss = css`
  background: var(
    --GradientParty-Off,
    linear-gradient(
      0deg,
      rgba(44, 255, 128, 0.5) -2.76%,
      rgba(44, 255, 128, 0.14) 1.1%,
      rgba(44, 255, 128, 0) 50.25%
    ),
    rgba(27, 53, 36, 0.3)
  );
`

const StyledTableItem = styled(Header5)`
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  font-weight: 400;
`

const StyledTableRowMainItem = styled(StyledTableItem)`
  display: grid;
  grid-template-columns: 280px repeat(8, 70px);
  padding: 0 16px;
  gap: 11px;
  text-align: center;
`

interface WonMVPProps {
  isWinMVP: boolean
}

const StyledTableRow = styled.div<WonMVPProps>`
  position: relative;
  display: grid;
  grid-template-columns: 940px 77px repeat(3, 120px);
  gap: 8px;

  ${StyledTableItem}, ${StyledTableRowMainItem} {
    ${({ isWinMVP }) => isWinMVP && winMVPcss}
  }
`

const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  width: 17px;
  margin-right: 7.5px;
`

interface StyledTeamNameProps {
  $isWin: boolean
}

const StyledMVPLabel = styled.div<StyledTeamNameProps>`
  position: absolute;
  top: -1px;
  left: -43px;
  width: 49px;
  height: 62px;
  background-image: url(${({ $isWin }) => ($isWin ? MVPwonIcon : MVPlostIcon)});
`

interface StyledTeamNameProps {
  $isWin: boolean
}

const StyledReportLabel = styled.div`
  position: absolute;
  top: -1px;
  left: -43px;
  width: 49px;
  height: 62px;
  background-image: url(${reportedPlayerIcon});
`

export default TeamParticipants
