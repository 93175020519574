import styled from 'styled-components'

import { DtoClaim } from 'api/schemas/judgeApi'
import MatchInfoHeader from 'components/MatchResults/MatchInfoHeader'
import MatchResult1x1CSContent from 'components/MatchResults/MatchResult1x1CSContent'
import RemainingTimer from 'components/RemainingTimer/RemainingTimer'
import ResultsMatchScoreboard from 'components/MatchResults/MatchResultsScoreboard'
import WinnerLeftPart from 'components/MatchResults/WinnerLeftPart'
import WinnerTeam from 'components/MatchResults/WinnerTeam'

interface ReportMatchInfoProps {
  claim: DtoClaim
  totalMinutesToDecide: number
  remainingMinutesToDecide: number
}

const ReportMatchInfo = ({
  claim,
  totalMinutesToDecide,
  remainingMinutesToDecide,
}: ReportMatchInfoProps) => {
  const is1x1 = claim.reportedMatch?.participants?.length === 2

  return (
    <StyledWrapper>
      <StyledMainInfo>
        <WinnerTeam />
        <MatchInfoHeader wrapperStyles={{ gap: 48 }} />
        {remainingMinutesToDecide > 0 && (
          <RemainingTimer
            currentProgress={remainingMinutesToDecide}
            total={totalMinutesToDecide}
          />
        )}
      </StyledMainInfo>

      {is1x1 ? (
        <StyledResults1x1Wrapper>
          <MatchResult1x1CSContent
            reportable={false}
            useRelativeTeamLabels={false}
            reportedUserId={claim.reportedUser?.id}
          />
        </StyledResults1x1Wrapper>
      ) : (
        <StyledResultScoreboardWrapper>
          <WinnerLeftPart />
          <ResultsMatchScoreboard
            playerExtraCardType="report"
            reportedUserId={claim.reportedUser?.id}
          />
        </StyledResultScoreboardWrapper>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const StyledMainInfo = styled.div`
  position: relative;
  width: 100%;
  margin-top: -48px;
  margin-left: 320px;
  display: flex;
  justify-content: center;
  gap: 48px;
`

const StyledResults1x1Wrapper = styled.div`
  padding: 0 54px 0 20px;
`

const StyledResultScoreboardWrapper = styled.div`
  display: flex;
`

export default ReportMatchInfo
