import { useMemo } from 'react'

import { DtoCSProfileStat } from 'api/schemas/lobbyApi'

const usePlayerStatisticCS = (statistic: DtoCSProfileStat) =>
  useMemo(() => {
    const {
      KD = 0,
      HS = 0,
      ADR = 0,
      score = 0,
      matchWinCount = 0,
      matchDrawCount = 0,
      matchLoseCount = 0,
    } = statistic

    const totalMatchCount = matchWinCount + matchDrawCount + matchLoseCount
    const winratio = +(
      totalMatchCount && (matchWinCount * 100) / totalMatchCount
    ).toFixed(2)

    return {
      winratio,
      totalMatchCount,
      KD: +KD.toFixed(2),
      HS: +HS.toFixed(2),
      ADR: +ADR.toFixed(2),
      winCount: matchWinCount,
      score: +score.toFixed(2),
      drawCount: matchDrawCount,
      loseCount: matchLoseCount,
    }
  }, [statistic])

export default usePlayerStatisticCS
