import { useMemo } from 'react'
import { useStore } from '@nanostores/react'
import dayjs from 'dayjs'

import { $currentClaim } from 'stores/judgeStore'
import { EnumJudgeClaimStatus } from 'api/schemas/judgeApi'

const useClaimTimer = () => {
  const claim = useStore($currentClaim)

  return useMemo(() => {
    const result = { total: 0, remaining: 0 }

    if (!claim) return result

    if (
      !claim.myDecision?.expiredAt ||
      !claim.myDecision?.takeToWork ||
      claim.myDecision?.status !==
        EnumJudgeClaimStatus.JudgeClaimStatusInProgress
    )
      return result

    const expiredAt = dayjs(claim.myDecision.expiredAt)

    if (expiredAt.isBefore(new Date())) return result

    result.total = expiredAt.diff(claim.myDecision.takeToWork, 'minutes')
    result.remaining = expiredAt.diff(new Date(), 'minutes')

    return result
  }, [claim])
}
export default useClaimTimer
