import {
  $friendsListSorted,
  FriendProfile,
  deleteFriend,
  inviteFriend,
  toggleFriendToFavorite,
} from 'stores/friendsStore'
import {
  $lobby,
  changeLobbyHost,
  inviteToLobby,
  joinToLobby,
  kickFromLobby,
} from 'stores/lobbyStore'
import {
  $party,
  changePartyHost,
  inviteToParty,
  kickFromParty,
} from 'stores/partyStore'
import { $userPopupId } from 'stores/statisticStore'
import { $userProfile, Profile } from 'stores/userStore'
import { ContextMenuItem } from 'rctx-contextmenu'
import { DtoUser } from 'api/schemas/lobbyApi'
import { Header6 } from 'styles/typography'
import { getPartnerChat } from 'stores/chatStores/singleChat'
import { openModal, setIsShowExitPopup } from 'stores/appStore'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled, { css } from 'styled-components'

interface StyledMenuItemProps {
  $isRed?: boolean
}

const StyledMenuItem = styled(ContextMenuItem)<StyledMenuItemProps>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: black;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }

  ${({ $isRed }) => css`
    background-color: ${$isRed ? '#ffcd29' : 'rgba(29, 30, 34, 0.2)'};
    padding: 9px 12px;
    color: ${$isRed ? 'black' : 'white'};

    &:hover {
      background-color: ${$isRed
        ? 'rgba(213, 167, 18, 1)  !important'
        : 'rgba(29, 30, 34, 0.7) !important'};
    }
  `}

  margin: 0;
  transition: 0.2s;
`

interface MenuProps {
  profile: FriendProfile | Profile | DtoUser
}

const UserMenu: React.FC<MenuProps> = ({ profile }) => {
  const { t } = useTranslation()

  const friends = useStore($friendsListSorted)
  const party = useStore($party)
  const currentUser = useStore($userProfile)
  const lobby = useStore($lobby)

  const isFriend = friends.some((friend) => friend.id === profile.id)
  const isPartyHost = party?.hostId === currentUser?.id
  const isLobbyHost = lobby?.hostId === currentUser?.id
  const isPartyMember = party?.participants?.some(
    (friend) => friend.user?.id === profile.id
  )

  const isLobbyMember = lobby?.participants?.some(
    (friend) => friend.user?.id === profile.id
  )
  const friendLobbyId = 'lobbyId' in profile ? profile.lobbyId : null

  const formattedProfile: FriendProfile | Profile = isFriend
    ? friends.find((friend) => friend.id === profile.id) || profile
    : profile

  const isUserProfile = (
    profile: FriendProfile | Profile
  ): profile is Profile => {
    return (profile as FriendProfile).isChosen === undefined
  }

  const showUserProfile = () => {
    $userPopupId.set(profile.id || currentUser?.id || null)
    openModal('personal')
  }

  const isUser = isUserProfile(formattedProfile)

  const openSteamAccount = async () => {
    const id = profile.steamId

    if (id) {
      await window.shell.openExternal(
        `https://steamcommunity.com/profiles/${id}/`
      )
    }
  }

  return (
    <>
      <div>
        {isPartyMember && currentUser?.id !== profile.id && isPartyHost && (
          <>
            <Header6>
              <StyledMenuItem
                onClick={() => changePartyHost(formattedProfile.id || '')}
              >
                {t('friends.menu.changePartyHost')}
              </StyledMenuItem>
            </Header6>
          </>
        )}
        {isLobbyMember && currentUser?.id !== profile.id && isLobbyHost && (
          <>
            <Header6>
              <StyledMenuItem
                onClick={() => changeLobbyHost(formattedProfile.id || '')}
              >
                {t('friends.menu.changeLobbyHost')}
              </StyledMenuItem>
            </Header6>
          </>
        )}
        {isFriend && !isUser && (
          <>
            {formattedProfile?.isChosen ? (
              <Header6>
                <StyledMenuItem
                  onClick={() =>
                    toggleFriendToFavorite(formattedProfile.id || '')
                  }
                >
                  {t('friends.menu.removeFavorite')}
                </StyledMenuItem>
              </Header6>
            ) : (
              <Header6>
                <StyledMenuItem
                  onClick={() =>
                    toggleFriendToFavorite(formattedProfile.id || '')
                  }
                >
                  {t('friends.menu.addFavorite')}
                </StyledMenuItem>
              </Header6>
            )}

            <Header6>
              <StyledMenuItem onClick={showUserProfile}>
                {t('friends.menu.lookAtProfile')}
              </StyledMenuItem>
            </Header6>
            <Header6>
              <StyledMenuItem onClick={openSteamAccount}>
                {t('friends.menu.openSteam')}
              </StyledMenuItem>
            </Header6>
            <Header6>
              <StyledMenuItem
                onClick={() => getPartnerChat(formattedProfile.id || '')}
              >
                {t('friends.menu.startAChat')}
              </StyledMenuItem>
            </Header6>

            {(!party ||
              !party?.participants?.some(
                (item) => item.user?.id === profile.id
              )) && (
              <Header6>
                <StyledMenuItem
                  onClick={() => inviteToParty(formattedProfile.id || '')}
                >
                  {t('friends.menu.addToParty')}
                </StyledMenuItem>
              </Header6>
            )}
            {lobby &&
              !lobby.participants?.some(
                (item) => item.user?.id === profile.id
              ) && (
                <Header6>
                  <StyledMenuItem
                    onClick={() => inviteToLobby(formattedProfile.id || '')}
                  >
                    {t('friends.menu.addToLobby')}
                  </StyledMenuItem>
                </Header6>
              )}
            {friendLobbyId && (
              <Header6>
                <StyledMenuItem onClick={() => joinToLobby(friendLobbyId)}>
                  {t('friends.menu.joinInLobby')}
                </StyledMenuItem>
              </Header6>
            )}
            <Header6>
              <StyledMenuItem
                $isRed
                onClick={() => deleteFriend(formattedProfile.id || '')}
              >
                {t('friends.menu.kickFromFriends')}
              </StyledMenuItem>
            </Header6>
          </>
        )}
        {isUser && currentUser?.id === profile.id && (
          <>
            <Header6>
              <StyledMenuItem onClick={showUserProfile}>
                {t('friends.menu.lookAtProfile')}
              </StyledMenuItem>
            </Header6>
            <Header6>
              <StyledMenuItem onClick={openSteamAccount}>
                {t('friends.menu.openSteam')}
              </StyledMenuItem>
            </Header6>
            <Header6>
              <StyledMenuItem onClick={() => openModal('settings')}>
                {t('friends.menu.settings')}
              </StyledMenuItem>
            </Header6>
            <Header6>
              <StyledMenuItem $isRed onClick={() => setIsShowExitPopup(true)}>
                {t('friends.menu.exit')}
              </StyledMenuItem>
            </Header6>
          </>
        )}
        {!isFriend && currentUser?.id !== profile.id ? (
          <>
            <Header6>
              <StyledMenuItem
                onClick={() => inviteFriend(formattedProfile.id || '')}
              >
                {t('friends.menu.addFriends')}
              </StyledMenuItem>
            </Header6>
            <Header6>
              <StyledMenuItem onClick={showUserProfile}>
                {t('friends.menu.lookAtProfile')}
              </StyledMenuItem>
            </Header6>
          </>
        ) : (
          <></>
        )}
        {isPartyMember && currentUser?.id !== profile.id && isPartyHost && (
          <>
            <Header6>
              <StyledMenuItem
                $isRed
                onClick={() => kickFromParty(formattedProfile.id || '')}
              >
                {t('friends.menu.kickParty')}
              </StyledMenuItem>
            </Header6>
          </>
        )}
        {isLobbyMember && currentUser?.id !== profile.id && isLobbyHost && (
          <>
            <Header6>
              <StyledMenuItem
                $isRed
                onClick={() => kickFromLobby(formattedProfile.id || '')}
              >
                {t('friends.menu.kickFromLobby')}
              </StyledMenuItem>
            </Header6>
          </>
        )}
      </div>
    </>
  )
}

export default React.memo(UserMenu)
