import { useMemo } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $currentClaim } from 'stores/judgeStore'
import { EnumClaimStatus, EnumJudgeClaimStatus } from 'api/schemas/judgeApi'
import InfoTooltipIcon from 'components/Tooltip/Info/InfoTooltipIcon'

const useReportModalTabs = () => {
  const { t } = useTranslation()

  const claim = useStore($currentClaim)

  return useMemo(() => {
    if (!claim) return []

    const isJudgeDeciding =
      claim.myDecision?.status ===
      EnumJudgeClaimStatus.JudgeClaimStatusInProgress
    const isFinished = [
      EnumClaimStatus.ClaimStatusFinish,
      EnumClaimStatus.ClaimStatusApproved,
    ].includes(claim.status!)

    const getDecisionLabel = () => {
      if (isFinished) {
        return t('judge.reportModal.results')
      }

      if (isJudgeDeciding) {
        return t('judge.reportModal.applicationResult')
      }

      return (
        <StyledTooltipedItem>
          {t('judge.reportModal.results')}
          <InfoTooltipIcon
            text={t('judge.reportModal.resultsUnavailable')}
            place="right"
          />
        </StyledTooltipedItem>
      )
    }

    return [
      {
        key: 'applicationInfo',
        label: t('judge.reportModal.application'),
      },
      {
        key: 'matchInfo',
        label: t('judge.reportModal.matchInfo'),
      },
      {
        key: 'decision',
        label: getDecisionLabel(),
        disabled: !isFinished && !isJudgeDeciding,
      },
    ]
  }, [claim, t])
}

const StyledTooltipedItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 16px;
    height: 16px;
  }
`

export default useReportModalTabs
