import { useStore } from '@nanostores/react'
import styled from 'styled-components'

import { $match } from 'stores/lobbyStore'
import MatchDemo from 'components/MatchResults/MatchDemo'

const ReportBottomSlot = () => {
  const match = useStore($match)

  if (!match) return null

  return (
    <StyledWrapper>
      <MatchDemo match={match} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`

export default ReportBottomSlot
