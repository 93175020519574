import { Tab } from 'rc-tabs/lib/interface'
import Tabs from 'rc-tabs'
import styled from 'styled-components'

interface StyledTabsProps {
  $noPadding: boolean
}

const StyledTabs = styled(Tabs)<StyledTabsProps>`
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: ${({ $noPadding }) => ($noPadding ? 0 : '10px')};

  & .rc-tabs-nav-list {
    display: flex;
  }

  & .rc-tabs-nav-operations {
    display: none;
  }

  & .rc-tabs-tab {
    font-family: 'Ruberoid';
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 18px;
    line-height: 100%;
    cursor: pointer;
    transition: 0.2s;

    ${({ $noPadding }) =>
      $noPadding && 'border: 1px solid rgba(255, 255, 255, 0.25)'}
  }

  & .rc-tabs-tab-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  & .rc-tabs-tab-disabled {
    color: #ffffff26;
  }

  & .rc-tabs-tab-active {
    transition: 0.2s;
    color: black;
  }
  & .rc-tabs-ink-bar {
    border-radius: 3px;
    background: var(--Desktop-Green, #2cff80);
    /* padding: 10px 18px; */
    height: 36px;
    z-index: -1;
    position: absolute;
    box-sizing: content-box;
    transition: 0.2s;
  }
`

interface TabsSwitcher<T> {
  defaultActiveKey: T
  onChange: (key: T) => void
  items: Array<Tab>
  noPadding?: boolean
}

const TabsSwitcher = <T extends string>({
  items,
  onChange,
  defaultActiveKey,
  noPadding = false,
}: TabsSwitcher<T>) => {
  return (
    <StyledTabs
      items={items}
      onChange={(activeKey) => onChange(activeKey as T)}
      defaultActiveKey={defaultActiveKey}
      $noPadding={noPadding}
    />
  )
}

export default TabsSwitcher
