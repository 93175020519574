import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import styled from 'styled-components'

import { $reportPopupId, getClaim } from 'stores/judgeStore'
import JudgeReportModal from 'components/Judge/Report/JudgeReportModal'
import Loading from 'components/Loading'
import ModalWrapper from 'components/Modals/ModalWrapper'

const ReportModal = () => {
  const claimId = useStore($reportPopupId)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getClaimInfo = async () => {
      if (!claimId) return
      await getClaim(claimId)
      setIsLoading(false)
    }

    void getClaimInfo()
  }, [claimId])

  if (isLoading) {
    return (
      <StyledModalWrapper>
        <Loading />
      </StyledModalWrapper>
    )
  }

  return (
    <StyledModalWrapper>
      <JudgeReportModal />
    </StyledModalWrapper>
  )
}

const StyledModalWrapper = styled(ModalWrapper)`
  width: 1648px;
`

export default ReportModal
