import { ReactNode } from 'react'
import styled from 'styled-components'

import ResultsMatchHistory from 'components/MatchResults/MatchResultsHistory'
import ResultsMatchScoreboard from 'components/MatchResults/MatchResultsScoreboard'
import ScoreboardPlayerActions from 'components/MatchResults/ScoreboardPlayerActions'
import WinnerLeftPart from 'components/MatchResults/WinnerLeftPart'

interface Props {
  tab: 'history' | 'scoreboard'
}

const MatchResultCustomCS = ({ tab }: Props) => {
  return (
    <StyledResultScoreboardWrapper>
      <WinnerLeftPart />
      {tabToContent[tab]}
    </StyledResultScoreboardWrapper>
  )
}

const tabToContent: Record<Props['tab'], ReactNode> = {
  history: <ResultsMatchHistory />,
  scoreboard: (
    <ResultsMatchScoreboard
      renderPlayerActions={(player) => (
        <ScoreboardPlayerActions player={player} />
      )}
      playerExtraCardType="mvp"
    />
  ),
}

const StyledResultScoreboardWrapper = styled.div`
  display: flex;
`

export default MatchResultCustomCS
