import { useStore } from '@nanostores/react'
import styled from 'styled-components'

import { $match } from 'stores/lobbyStore'
import { Header2 } from 'styles/typography'
import Coin from 'images/Coin'
import MatchDemo from 'components/MatchResults/MatchDemo'
import MatchInfoHeader from 'components/MatchResults/MatchInfoHeader'
import MatchResult1x1CSContent from 'components/MatchResults/MatchResult1x1CSContent'
import ModalHeader from 'components/Modals/ModalHeader'
import ModalWrapper from 'components/Modals/ModalWrapper'
import ReportServerButton from 'components/ReportServerButton'

const MatchResult1x1CS = () => {
  const match = useStore($match)

  if (!match) return null

  return (
    <StyledModalWrapper>
      <StyledModalHeader>
        <ModalHeader modalType="matchResults" />
      </StyledModalHeader>
      <StyledWrapper>
        <StyledHeader>
          <StyledMainInfo>
            <MatchInfoHeader />
          </StyledMainInfo>

          <StyledBalance>
            <StyledCoin />
            <div>{match?.bet}</div>
          </StyledBalance>
        </StyledHeader>

        <MatchResult1x1CSContent />

        <StyledButtonsWrapper>
          <MatchDemo match={match} />
          <ReportServerButton matchId={match.id!} />
        </StyledButtonsWrapper>
      </StyledWrapper>
    </StyledModalWrapper>
  )
}

const StyledModalWrapper = styled(ModalWrapper)`
  /* padding-bottom: 38px; */
`

const StyledModalHeader = styled.div`
  padding: 13px 11px 13px 20px;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 40px 40px 20px;
`

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 20px;
  margin-bottom: 28px;
`

const StyledMainInfo = styled.div`
  width: 100%;
  justify-content: center;
  position: relative;
  display: flex;
  gap: 64px;
`

const StyledBalance = styled(Header2)`
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

const StyledCoin = styled(Coin)`
  fill: #ffcd29;
  height: 42px;
  width: 42px;
  margin-right: 20px;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 20px;
`

export default MatchResult1x1CS
