import React from 'react'

import {
  AppealStageTypes,
  handleCreateNewAppeal,
} from 'stores/chatStores/appealStore'
import { EnumClaimReportCategory } from 'api/schemas/supportApi'
import { closeCurrentModal } from 'stores/appStore'
import { setTabActive } from 'stores/chatStores/chatStore'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import ReportServerIcon from 'images/ReportServerIcon.svg'
import styled from 'styled-components'

interface Props {
  matchId: string
}

const ReportServerButton = ({ matchId }: Props) => {
  const { t } = useTranslation()

  const handleMatchReport = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    closeCurrentModal()
    setTabActive('support', true)
    await handleCreateNewAppeal({
      game: 'CS 2',
      reportedMatchId: matchId,
      nextStep: AppealStageTypes.ADD_FILES,
      prevStep: AppealStageTypes.CHOOSE_MATCH,
      currentStep: AppealStageTypes.CHOOSE_MATCH_REASON,
      category: EnumClaimReportCategory.ClaimReportCategoryMatchReport,
    })
  }

  return (
    <Button view="primary" onClick={handleMatchReport}>
      <StyledReportServerIcon />
      {t('results.scoreboard.serverReport')}
    </Button>
  )
}

const StyledReportServerIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background: url(${ReportServerIcon});
`

export default ReportServerButton
