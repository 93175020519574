import styled from 'styled-components'

const StyledWindowLine = styled.div`
  width: 100%;
  height: 31px;
  background: rgba(29, 30, 34, 1);
  position: relative;
  z-index: 99999;
  -webkit-app-region: drag;
`

const WindowLine: React.FC = () => {
  return <StyledWindowLine id="window_line" />
}

export default WindowLine
