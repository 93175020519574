import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DtoJudgeClaim } from 'api/schemas/judgeApi'
import { Header1, SideHeader1, Text4, Text5 } from 'styles/typography'
import JudgeAnonymus1 from 'images/judge/judgeAnonymus1.png'
import JudgeAnonymus2 from 'images/judge/judgeAnonymus2.png'
import JudgeAnonymus3 from 'images/judge/judgeAnonymus3.png'
import JudgeTicketResultTexture from 'images/judge/judgeTicketResultTexture.svg'
import JudgeVoterAvatarFrame from 'images/JudgeVoterAvatarFrame'

const judgeAvatars = [JudgeAnonymus1, JudgeAnonymus2, JudgeAnonymus3]

interface VoteProps {
  position: number
  decision: DtoJudgeClaim
}

const ShortVoterCard: React.FC<VoteProps> = ({ decision, position }) => {
  const { t } = useTranslation()

  return (
    <VoterWrapper>
      <HeaderWrapper>
        <VoterInfo>
          <VoterAvatarWrapper>
            <VoterAvatarFrame fill={getColor(decision.isGuilty)} />
            <VoterAvatar
              src={judgeAvatars[position - 1]}
              $isGuilty={decision.isGuilty}
            />
          </VoterAvatarWrapper>
          <VoterNumber>0{position}</VoterNumber>
        </VoterInfo>
        <StyledPositionContainer>
          <StyledPosition $isGuilty={decision.isGuilty} $position={position} />
          <StyledPosition $isGuilty={decision.isGuilty} $position={position} />
          <StyledPosition $isGuilty={decision.isGuilty} $position={position} />
        </StyledPositionContainer>
      </HeaderWrapper>
      <VoterReasoning>
        <ReasoningInfo>
          <VoterTiming>
            <StyledGrayDescription>
              {t('judge.reportModal.timing')}
            </StyledGrayDescription>
            <StyledWhiteDescription>
              {decision.timeCodes?.[0].join(' - ') || '-'}
            </StyledWhiteDescription>
          </VoterTiming>
          <VoterArguments>
            <StyledReason>
              <StyledReasonText>
                <StyledGrayDescription>
                  {t('judge.reportModal.descrpition')}
                </StyledGrayDescription>
              </StyledReasonText>
              <StyledReasonContainer>
                <StyledScrollbar>
                  <StyledReasonDescription>
                    {decision.description}
                  </StyledReasonDescription>
                </StyledScrollbar>
              </StyledReasonContainer>
            </StyledReason>
          </VoterArguments>
        </ReasoningInfo>
        {!!decision.attachments?.length && (
          <ReasoningAttachment>
            <StyledGrayDescription>Фото</StyledGrayDescription>
            <AttachmentWrapper>
              <StyledAttachmentWrapper>
                {(decision.attachments || []).map(
                  ({ attachmentId, attachmentUrl, attachmentName }) => (
                    <PhotoAttachment
                      key={attachmentId}
                      src={attachmentUrl}
                      alt={attachmentName}
                    />
                  )
                )}
              </StyledAttachmentWrapper>
            </AttachmentWrapper>
          </ReasoningAttachment>
        )}
      </VoterReasoning>
      <FooterWrapper>
        <StyledFooterWrapper>
          <StyledDecisionDescription>
            {t('judge.reportModal.decision')}
          </StyledDecisionDescription>
          <GuiltyContainer $isGuilty={decision.isGuilty}>
            {decision.isGuilty
              ? t('judge.reportModal.guilty')
              : t('judge.reportModal.notGuilty')}
          </GuiltyContainer>
        </StyledFooterWrapper>
      </FooterWrapper>
    </VoterWrapper>
  )
}
export default ShortVoterCard

interface StyledPositionProps {
  $isGuilty?: boolean
  $position?: number
}

const getColor = (isGuilty: boolean | undefined) => {
  return isGuilty ? '#FFCD29' : '#2CFF80'
}

const VoterWrapper = styled.div`
  width: 473px;
  height: 100%;
  display: flex;
  border-radius: 3px;
  border: 1px solid #ffffff26;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  background: url(${JudgeTicketResultTexture}),
    linear-gradient(0deg, rgba(29, 30, 34, 0.5), rgba(29, 30, 34, 0.5)),
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    );
`

const HeaderWrapper = styled.div`
  width: 100%;
  height: 127px;
  display: flex;
  justify-content: space-between;
`
const VoterInfo = styled.div`
  width: 234px;
  height: 100%;
  border: 1px solid #ffffff26;
  border-radius: 0px 0px 25px 0px;
  border-top: none;
  border-left: none;
  display: flex;
  gap: 18px;
  padding: 15px 0px 0px 21px;
`
const VoterAvatarWrapper = styled.div`
  width: 89px;
  height: 89px;
  position: relative;
`
const VoterAvatarFrame = styled(JudgeVoterAvatarFrame)``
const VoterAvatar = styled.img<StyledPositionProps>`
  position: absolute;
  left: 8px;
  top: 8px;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 1px solid ${({ $isGuilty }) => getColor($isGuilty)};
  background: grey;
`
const VoterNumber = styled(Header1)`
  font-size: 64px;
  line-height: 120px;
  font-weight: 400;
`
const StyledPositionContainer = styled.div`
  margin: 22px 22px 0px 0px;
  width: 66px;
  height: 27px;
  border-radius: 28px;
  border: 1px solid #ffffff26;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`
const StyledPosition = styled.div<StyledPositionProps>`
  border-radius: 34px;
  background: ${({ $isGuilty }) => getColor($isGuilty)};
  height: 7px;
  width: 7px;
  ${({ $position }) =>
    $position &&
    `  
    &:nth-child(${$position}) {  
      width: 24px;  
    }  
  `}
`
const VoterReasoning = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-self: flex-start;
`
const ReasoningInfo = styled.div`
  width: 100%;
  height: 100%;
`
const VoterTiming = styled(Text5)`
  padding: 0px 0px 0px 12px;
  font-weight: 500;
  line-height: 16px;
`
const StyledGrayDescription = styled.div`
  opacity: 0.3;
`
const StyledWhiteDescription = styled.div`
  color: #ffffff;
`
const VoterArguments = styled.div``
const StyledReason = styled.div`
  margin: 14px 0px 0px 12px;
`
const StyledReasonText = styled(Text5)`
  padding: 0px 0px 3px 0px;
  font-weight: 500;
`
const StyledReasonContainer = styled.div`
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #ffffff26;
  width: 267px;
  height: 243px;
`
const StyledReasonDescription = styled(Text5)`
  font-weight: 500;
  padding: 7px 9px 0px 9px;
`
const StyledScrollbar = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + -3px);
  height: 100%;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`
const ReasoningAttachment = styled(Text5)`
  width: 100%;
  height: 100%;
`
const AttachmentWrapper = styled.div`
  margin: 3px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  gap: 6px;
`
const StyledAttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const PhotoAttachment = styled.img`
  width: 142px;
  height: 80px;
  border-radius: 4px;
  border: 3px solid rgba(255, 255, 255, 0.15);
`

const FooterWrapper = styled.div`
  margin: 13px 0px 0px 0px;
  width: 100%;
`
const StyledFooterWrapper = styled.div`
  padding: 0px 10px 24px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`
const StyledDecisionDescription = styled(SideHeader1)<StyledPositionProps>`
  line-height: 40px;
  font-size: 52px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #ffffff14;
  text-shadow: 0px -2px 0px #00000040;
`
const GuiltyContainer = styled(Text4)<StyledPositionProps>`
  color: black;
  width: 137px;
  height: 36px;
  border-radius: 45px;
  background: ${({ $isGuilty }) => getColor($isGuilty)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin: 0px 5px 0px 0px;
`
