import {
  $userPopupGameFormat,
  $userPopupId,
  GameFormat,
  getUserInfo,
  getuserPopupStat,
  setUserPopupGameFormat,
} from 'stores/statisticStore'
import { $userProfile } from 'stores/userStore'
import { getfriendsStatistic } from 'stores/friendsStore'
import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import Loading from 'components/Loading'
import ModalHeader from 'components/Modals/ModalHeader'
import ModalWrapper from 'components/Modals/ModalWrapper'
import PeronalTopInfo from 'components/PersonalAccount/PeronalTopInfo'
import PersonalFriendsList from 'components/PersonalAccount/PersonalFriendsList'
import PersonalStatisticBlocks from 'components/PersonalAccount/PersonalStatisticBlocks'
import TabsSwitcher from 'components/TabsSwitcher'
import Toggle from 'components/Toggle'
import styled from 'styled-components'

const UserAccount: React.FC = () => {
  const { t } = useTranslation()
  const [currentId, setCurrentId] = useState<string | null>(null)
  const [tab, setTab] = useState<GameFormat>('CUSTOM')
  const currentUser = useStore($userProfile)
  const user = useStore($userPopupId)
  const userPopupGameFormat = useStore($userPopupGameFormat)

  type GameTypes = '5X5' | '1X1'
  const RATING: GameTypes[] = ['5X5', '1X1']
  const [rating, setRating] = useState<GameTypes>(
    userPopupGameFormat === 'FIVE_TO_FIVE' ? '5X5' : ('1X1' as GameTypes)
  )
  const handleRatingChange = (rating: GameTypes) => {
    if (rating === '1X1') {
      setUserPopupGameFormat('ONE_TO_ONE')
    } else {
      setUserPopupGameFormat('FIVE_TO_FIVE')
    }
    setRating(rating)
  }

  useEffect(() => {
    const getData = async () => {
      if (!user && !currentUser) return
      await getfriendsStatistic((user || currentUser?.id) as string)
      await getuserPopupStat((user || currentUser?.id) as string)
      await getUserInfo((user || currentUser?.id) as string)
      setCurrentId(user)
      setUserPopupGameFormat('CUSTOM')
      setTab('CUSTOM')
      setRating('1X1')
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getData()
  }, [currentUser, user])

  return (
    <ModalWrapper>
      <StyledModalHeader>
        <ModalHeader modalType="personal" />
      </StyledModalHeader>
      <StyledWrapper>
        {currentId === user ? (
          <>
            <PeronalTopInfo />
            {/* <TogglersBlock /> */}
            <StyledTabsWrapper>
              <TabsSwitcher
                defaultActiveKey={tab}
                onChange={(key: string) => {
                  setUserPopupGameFormat(key as GameFormat)
                  setTab(key as GameFormat)
                }}
                items={[
                  {
                    key: 'ONE_TO_ONE',
                    label: t('play.tabs.rating'),
                  },
                  {
                    key: 'CUSTOM',
                    label: t('play.tabs.custom'),
                  },
                ]}
              />
              {userPopupGameFormat !== 'CUSTOM' && (
                <Toggle
                  value={rating}
                  items={RATING}
                  padding="2.5px 23px"
                  onChange={handleRatingChange}
                />
              )}
            </StyledTabsWrapper>
            <PersonalStatisticBlocks />
            <PersonalFriendsList />
          </>
        ) : (
          <Loading />
        )}
      </StyledWrapper>
    </ModalWrapper>
  )
}

const StyledWrapper = styled.div`
  padding: 0px 30px;
  width: 1630px;
  max-height: 900px;
  min-height: 900px;
  height: 100%;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const StyledModalHeader = styled.div`
  padding: 20px 20px 20px 30px;
`

const StyledTabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`

export default UserAccount
