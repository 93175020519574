import { useStore } from '@nanostores/react'
import styled from 'styled-components'

import { $currentClaim } from 'stores/judgeStore'
import { EnumClaimStatus } from 'api/schemas/judgeApi'
import JudgeClaimResults from 'components/Judge/Report/JudgeClaimResults'
import JudgeDecisionForm from 'components/Judge/Report/JudgeDecisionForm'
import VoteProcess from 'components/Judge/ClaimInfo/VoteProcess'

interface JudgeClaimDecisionProps {
  totalMinutesToDecide: number
  remainingMinutesToDecide: number
}

const JudgeClaimDecision = ({
  totalMinutesToDecide,
  remainingMinutesToDecide,
}: JudgeClaimDecisionProps) => {
  const claim = useStore($currentClaim)

  if (!claim) return null

  const isFinished = [
    EnumClaimStatus.ClaimStatusApproved,
    EnumClaimStatus.ClaimStatusFinish,
  ].includes(claim.status!)

  return (
    <StyledWrapper>
      <VoteProcess
        totalMinutesToDecide={totalMinutesToDecide}
        remainingMinutesToDecide={remainingMinutesToDecide}
      />

      {isFinished ? <JudgeClaimResults /> : <JudgeDecisionForm claim={claim} />}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding: 0 40px 0 20px;
  margin-top: 28px;
`

export default JudgeClaimDecision
