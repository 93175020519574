import { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  $currentClaim,
  $currentWorkClaim,
  $reportPopupId,
} from 'stores/judgeStore'
import { EnumJudgeClaimStatus } from 'api/schemas/judgeApi'
import { Header3 } from 'styles/typography'
import { closeModal } from 'stores/appStore'
import { useStore } from '@nanostores/react'
import JudgeClaimDecision from 'components/Judge/Report/JudgeClaimDecision'
import JudgeClaimInfo from 'components/Judge/ClaimInfo/JudgeClaimInfo'
import ModalHeader from 'components/Modals/ModalHeader'
import ReportBottomSlot from 'components/Judge/Report/ReportBottomSlot'
import ReportMatchInfo from 'components/Judge/Report/ReportMatchInfo'
import TabsSwitcher from 'components/TabsSwitcher'
import useClaimTimer from 'components/RemainingTimer/useClaimTimer'
import useReportModalTabs from 'components/Judge/Report/useReportModalTabs'

type Tabs = 'applicationInfo' | 'matchInfo' | 'decision'

const JudgeReportModal = () => {
  const claim = useStore($currentClaim)

  const [tab, setTab] = useState<Tabs>('applicationInfo')

  const tabItems = useReportModalTabs()
  const { total, remaining } = useClaimTimer()

  useEffect(() => {
    if (
      claim?.myDecision?.status ===
        EnumJudgeClaimStatus.JudgeClaimStatusInProgress &&
      remaining === 0
    ) {
      $currentWorkClaim.set(null)
      $reportPopupId.set(null)
      closeModal('report')
    }
  }, [claim?.myDecision?.status, remaining])

  if (!claim?.reportedMatch || !claim?.reportedUser) {
    return null
  }

  const showBottomSlot =
    claim.myDecision?.status === EnumJudgeClaimStatus.JudgeClaimStatusInProgress

  return (
    <>
      <StyledModalHeader>
        <ModalHeader modalType="report" />
      </StyledModalHeader>

      <StyledTabsWrapper>
        <TabsSwitcher
          defaultActiveKey={'applicationInfo'}
          onChange={setTab}
          items={tabItems}
          noPadding
        />
      </StyledTabsWrapper>

      {tab === 'applicationInfo' && (
        <>
          <JudgeClaimInfo
            totalMinutesToDecide={total}
            remainingMinutesToDecide={remaining}
          />
          {showBottomSlot && (
            <StyledApplicationBottomSlot>
              <ReportBottomSlot />
            </StyledApplicationBottomSlot>
          )}
        </>
      )}
      {tab === 'matchInfo' && (
        <>
          <ReportMatchInfo
            claim={claim}
            totalMinutesToDecide={total}
            remainingMinutesToDecide={remaining}
          />
          {showBottomSlot && (
            <StyledMatchBottomSlot>
              <ReportBottomSlot />
            </StyledMatchBottomSlot>
          )}
        </>
      )}
      {tab === 'decision' && (
        <JudgeClaimDecision
          totalMinutesToDecide={total}
          remainingMinutesToDecide={remaining}
        />
      )}
    </>
  )
}

const StyledModalHeader = styled.div`
  padding: 13px 20px 0px 20px;
`

const StyledTabsWrapper = styled(Header3)`
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin-top: 14px;
  z-index: 10;
`

const StyledBottomSlot = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
`

const StyledApplicationBottomSlot = styled(StyledBottomSlot)`
  padding: 0 40px 0 20px;
`

const StyledMatchBottomSlot = styled(StyledBottomSlot)`
  padding: 0 53px 0 20px;
`

export default JudgeReportModal
