import { $userPopupId } from 'stores/statisticStore'
import { ContextMenu, ContextMenuTrigger } from 'rctx-contextmenu'
import { FC, useEffect, useState } from 'react'
import { FriendProfile } from 'stores/friendsStore'
import { Profile } from 'stores/userStore'
import { openModal } from 'stores/appStore'
import { v4 as uuidv4 } from 'uuid'
import ReactDOM from 'react-dom'
import UserMenu from 'components/ContextMenu/UserMenu'
import styled from 'styled-components'

const StyledMenuWrapper = styled(ContextMenu)`
  overflow: hidden;
  width: 194px;

  border-radius: 6px 0px 0px 6px;

  cursor: default;
  background-color: transparent;
  backdrop-filter: blur(15px);
  padding: 0;
  z-index: 999;
`

const StyledContainer = styled.div`
  cursor: pointer;
`

interface WithUserMenuProps {
  disableLClick?: boolean
  children: React.ReactNode
  profile?: FriendProfile | Profile
}

const WithUserMenu: FC<WithUserMenuProps> = ({
  profile,
  children,
  disableLClick,
}) => {
  const [id, setId] = useState(uuidv4())

  const showUserProfile = () => {
    if (disableLClick) return
    $userPopupId.set(profile?.id || null)
    openModal('personal')
  }

  useEffect(() => {
    setId(uuidv4())
  }, [profile])

  return profile ? (
    <>
      <ContextMenuTrigger id={id}>
        <StyledContainer onClick={showUserProfile}>{children}</StyledContainer>
      </ContextMenuTrigger>
      {ReactDOM.createPortal(
        <StyledMenuWrapper id={id} key={id}>
          <UserMenu profile={profile} />
        </StyledMenuWrapper>,
        document.body
      )}
    </>
  ) : (
    <>{children}</>
  )
}

export default WithUserMenu
