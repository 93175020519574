import { EnumJudgeClaimStatus } from 'api/schemas/judgeApi'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text4, Text5 } from 'styles/typography'

interface VoteProps {
  owner: boolean
  status: EnumJudgeClaimStatus | undefined
}

const VoteStatus: React.FC<VoteProps> = ({ owner, status }) => {
  const { t } = useTranslation()

  return (
    <JudgeVote $status={status}>
      <JudgeVoteResult>
        <JudgeVoteCircles>
          {Array.from({ length: getCirclesCount(status) }).map((_, i) => (
            <JudgeVoteCircle key={i} $status={status} />
          ))}
        </JudgeVoteCircles>
        {owner && (
          <JudgeVoteOwner $status={status}>
            {t('judge.reportModal.you')}
          </JudgeVoteOwner>
        )}
      </JudgeVoteResult>
      <JudgeVoteStatus $status={status}>
        {[
          EnumJudgeClaimStatus.JudgeClaimStatusFinished,
          EnumJudgeClaimStatus.JudgeClaimStatusInProgress,
        ].includes(status!)
          ? t(`judge.reportModal.${status!}`)
          : t('judge.reportModal.waiting')}
      </JudgeVoteStatus>
    </JudgeVote>
  )
}

const getCirclesCount = (status: EnumJudgeClaimStatus | undefined) => {
  switch (status) {
    case EnumJudgeClaimStatus.JudgeClaimStatusFinished:
      return 3
    case EnumJudgeClaimStatus.JudgeClaimStatusInProgress:
      return 2
    case EnumJudgeClaimStatus.JudgeClaimStatusDeclined:
    default:
      return 1
  }
}

const getElemColor = (status: EnumJudgeClaimStatus | undefined) => {
  switch (status) {
    case EnumJudgeClaimStatus.JudgeClaimStatusFinished:
      return 'black'
    case EnumJudgeClaimStatus.JudgeClaimStatusInProgress:
    case EnumJudgeClaimStatus.JudgeClaimStatusDeclined:
    default:
      return 'white'
  }
}

const getBackgroundColor = (status: EnumJudgeClaimStatus | undefined) => {
  switch (status) {
    case EnumJudgeClaimStatus.JudgeClaimStatusFinished:
      return '#FFCD29'
    case EnumJudgeClaimStatus.JudgeClaimStatusInProgress:
      return '#1D1E2280'
    case EnumJudgeClaimStatus.JudgeClaimStatusDeclined:
    default:
      return '#FFFFFF03'
  }
}

interface StyledVoteProps {
  $status: EnumJudgeClaimStatus | undefined
}

const JudgeVote = styled.div<StyledVoteProps>`
  display: flex;
  width: 113px;
  height: 108px;
  gap: 16px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ffffff26;
  background-color: ${({ $status }) => getBackgroundColor($status)};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const JudgeVoteResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const JudgeVoteCircles = styled.div`
  display: flex;
  gap: 4px;
`

const JudgeVoteCircle = styled.div<StyledVoteProps>`
  width: 9px;
  height: 9px;
  border-radius: 100%;
  border-width: 1px;
  border-style: ${({ $status }) => ($status ? 'solid' : 'dotted')};
  border-color: ${({ $status }) => getElemColor($status)};
`

const JudgeVoteStatus = styled(Text4)<StyledVoteProps>`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${({ $status }) => getElemColor($status)};
`

const JudgeVoteOwner = styled(Text5)<StyledVoteProps>`
  display: flex;
  justify-content: center;
  width: 32px;
  height: 14px;
  border-radius: 35px;
  background: #2cff80;
  color: black;
`
export default VoteStatus
